import { Component, Output, Input, LOCALE_ID, Inject } from '@angular/core';
import { User } from 'common/lib/auth/user';
import { AlertService } from 'common/lib/auth/alert.service';
import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { HttpHelper } from 'common/lib/utils/http.helper';
import { catchError, map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { Observable, throwError } from 'rxjs';
import { ConfigService } from 'common/lib/auth/config.service';


class ProfileType {
    key: string
    title: string
    value: boolean
    groups?: string[]
}

@Component({
    selector: 'app-profile-config',
    templateUrl: './profile.config.component.html',
    styleUrls: ['./profile.config.component.css']
})
export class ProfileConfigComponent {

    private _user: User;

    constructor(
        private http: HttpClient,
        private alert: AlertService,
        private config: ConfigService,
        @Inject(LOCALE_ID) public locale: string) {
    }

    private _profileTypes: ProfileType[] = [

        {
            key: 'sign',
            title: 'Sign Profile',
            value: true
        },

        {
            key: 'email',
            title: 'E-Mail',
            value: true,
            groups: ['mailbox']
        },
        {
            key: 'carddav',
            title: 'CardDAV',
            value: true,
            groups: ['addressbook']
        },
        {
            key: 'caldav',
            title: 'CalDAV',
            value: true,
            groups: ['calendar']
        },
        {
            key: 'ldap',
            title: 'LDAP',
            value: true
        },
        {
            key: 'wifi',
            title: 'Wifi',
            value: true,
            groups: ['wlan', 'wlan-guests']
        },
        /*{
            key: 'wifi-wpa3',
            title: 'Wifi-WPA3',
            value: false,
            groups: ['wlan', 'wlan-guests']
        },*/
        {
            key: 'vpn',
            title: 'VPN',
            value: false,
            groups: ['vpn']
        },
        {
            key: 'user',
            title: 'User Certificate',
            value: true
        },
        {
            key: 'ca',
            title: 'Certificate Authory Certificate',
            value: true
        }
    ]


    @Output()
    get profileTypes(): ProfileType[] {
        const res: ProfileType[] = []

        for (const t of this._profileTypes) {
            let ok = !t.groups;
            if (this._user && !ok) {
                for (const g of t.groups) {
                    if (this._user.groups[g]) {
                        ok = true;
                        break;
                    }
                }
            }

            if (ok) {
                res.push(t)
            }
        }

        return res
    }


    @Output()
    get selectedProfileTypes(): string[] {
        const res: string[] = []

        for (const p of this.profileTypes) {
            if (p.value) {
                res.push(p.key)
            }
        }

        return res
    }

    set selectedProfileTypes(keys: string[]) {
        for (const p of this.profileTypes) {
            p.value = keys.indexOf(p.key) >= 0;
        }
    }


    @Input()
    set user(user: User) {
        this._user = user;
    }

    get user(): User {
        return this._user;
    }

    get id(): string {
        if (!this._user || !this._user.id) {
            return null;
        }
        return this._user.id;
    }

    @Output()
    get isAuthorized(): boolean {
        const user = this.user;
        if (!user || !user.groups) {
            return false
        }
        return true//user.groups['vpn'] !== undefined;
    }


    get apiEndpoint(): string {
        if (!this.config.meta || !this.config.meta.url) {
            return null;
        }
        return this.config.meta.url + 'api/';
    }

    public getCmdEndpoint(user: string, cmd: string, subCmd?: string): string {
        const url = this.apiEndpoint;
        if (!url) {
            return null;
        }
        return url + cmd + (subCmd ? '/' + subCmd : '');
    }

    public getDownloadUrl(type: string): string {
        if (!this.isAuthorized) {
            return null;
        }

        return this.getCmdEndpoint(this.id, 'profile_config', type + '/signed/download');
    }

    public async loadConfig(type: string): Promise<string> {
        if (!this.isAuthorized) {
            return null;
        }

        let sign = true
        const options: string[] = []

        for (const p of this.profileTypes) {
            if (p.key === 'sign') {
                sign = p.value
                continue
            }

            if (p.value) {
                options.push(p.key)
            }
        }


        const url = this.getCmdEndpoint(this.id, 'profile_config', this.user.id + '/' + type + (sign ? '/signed' : ''));
        if (!url) {
            return null;
        }

        return HttpHelper.load(this.http, url, {
            timestamp: (+(new Date())) + '',
            'options[]': options
        }).pipe(
            catchError((error: HttpErrorResponse) => { return this.handleError(error, 'could not download profile config'); }),
            map((resp: any) => {
                if (resp.status !== 'success') {
                    this.alert.error('could not get config');
                    return null;
                }

                return atob(resp.config as string);
            })).toPromise();
    }

    public async downloadConfig(type: string) {

        this.loadConfig(type).then((config: string) => {
            if (!config) {
                return;
            }

            //saveAs(new File([config], this.id + '.mobileconfig', { type: "application/x-apple-aspen-config; charset=utf-8" }), this.id + '.mobileconfig', { autoBom: true });
            //saveAs(new File([config], this.id + '.mobileconfig', { type: "application/x-apple-aspen-config" }));
            //saveAs(new Blob([config], { type: "application/x-apple-aspen-config; charset=utf-8" }), this.id + '.mobileconfig', { autoBom: true });
            //saveAs(new Blob([config], { type: "application/x-apple-aspen-config" }), this.id + '.mobileconfig', { autoBom: true });

            const bytes = config;

            var byteNumbers = new Array(bytes.length);
            for (var i = 0; i < bytes.length; i++) {
                byteNumbers[i] = bytes.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);

            var blob = new Blob([byteArray], { type: "application/x-apple-aspen-config" });//; charset=utf-8" });
            saveAs(blob, this.id + '.mobileconfig');//, { autoBom: true });
        })
    }

    public handleError(error: HttpErrorResponse, msg: string): Observable<HttpEvent<any>> {
        this.alert.error(msg, true);
        //this.isLoading = false;

        return throwError(msg);
    }
}
