import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from './alert.service';

@Component({
    selector: 'lib-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;

    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.subscription = this.alertService.getAlert()
            .subscribe(message => {
                /*switch (message && message.type) {
                    case 'success':
                        this.error = null;
                        this.success = message;
                        break;

                    case 'error':
                        this.error = message;
                        this.success = null;
                        break;
                }*/

                this.message = message;
            });
    }

    ngOnDestroy() {
        this.message = null;
        this.subscription.unsubscribe();
    }
}
