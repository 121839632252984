import { NgModule, ModuleWithProviders, InjectionToken, Inject } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { LoginComponent } from './login.component';
import { AuthenticationService } from './authentication.service';
import { MaterialIncludeModule } from '../material-include/material-include.module';
import { AlertService } from './alert.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigService, AuthModuleConfig, AuthModuleConfigService } from './config.service';
import { ConfigData } from './config.data';
import { AlertComponent } from './alert.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthTokenInterceptor } from './auth.token.interceptor';
import { UserInfoComponent } from './userinfo.component';
import { SecureImagePipe } from './auth.image.pipe';


@NgModule({
  declarations: [
    LoginComponent,
    AlertComponent,
    UserInfoComponent,
    SecureImagePipe
  ],
  imports: [
    MaterialIncludeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    OAuthModule.forRoot()
  ],
  exports: [
    MaterialIncludeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoginComponent,
    AlertComponent,
    UserInfoComponent,
    SecureImagePipe
  ]
})
export class AuthModule {

  static forRoot(config: AuthModuleConfig): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
        ConfigService,
        AuthenticationService,
        AlertService,
        ConfigData,
        {
          provide: AuthModuleConfigService,
          useValue: config
        }
      ]
    }
  }

  /*constructor(@Inject(AuthModuleConfigService) private config) {
    console.log('module: ', config.oauthClient);
  }*/
}
