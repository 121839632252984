<form (ngSubmit)="onSubmit()" [formGroup]="registerForm" #formDirective="ngForm">
    <mat-card class="login-card">
        <mat-card-header>
            <mat-card-title>Login</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field appearance="fill">
                <mat-label i18n>Username</mat-label>
                <input matInput formControlName="user" required>
                <mat-error *ngIf="controls.user.hasError('required')">
                    Username is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n>Password</mat-label>
                <input matInput type="password" formControlName="pass" required minlength="6">
                <mat-error *ngIf="controls.pass.hasError('minlength')">
                    Password is <strong>too short</strong>
                </mat-error>
                <mat-error *ngIf="controls.pass.hasError('required')">
                    Password is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button type="submit" value="Submit" color="primary"
                [disabled]="isLoading || registerForm.invalid" i18n>Login</button>
        </mat-card-actions>
    </mat-card>
</form>