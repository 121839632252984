import { Injectable, Input, Output } from '@angular/core';
import { HttpHelper } from 'common/lib/utils/http.helper';
import { ConfigService } from 'common/lib/auth/config.service';



@Injectable()
export class Service {
    _timezone = 'UTC';

    constructor(private config: ConfigService) {

        this.config.onConfig(() => {
            this.reload();
        });
    }

    get baseUrl(): string {
        return this.config.baseUrlHelper.baseUrl;
    }

    @Output()
    get description(): string {
        return this.config.meta.description;
    }

    @Output()
    get host(): string {
        return this.config.meta.name;
    }



    reload() {
        // configs loaded
    }


    //////////////////////////////////////////////////



    load(url: string, parameters?: /*HttpParams |*/ { [param: string]: string | string[] }) {
        //this.error = '';

        return HttpHelper.load(this.config.http, url, parameters);

        /*return this.http.get(url, {
          params: parameters
        });*/
    }

}
