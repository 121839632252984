import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from 'common/lib/auth/login.component';
import { ProfileComponent } from './pages/profile.component';
import { AuthGuard } from 'common/lib/auth/auth.guard';
import { UsersComponent } from './pages/users.component';
import { StartComponent } from './pages/start.component';
import { DevicesComponent } from './pages/devices.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'intranet', pathMatch: 'full' },

  {
    path: 'intranet',
    
    children: [
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
      { path: 'users', component: UsersComponent, canActivate: [AuthGuard], data: { groups: ['useradmin'] } },
      { path: 'devices', component: DevicesComponent, canActivate: [AuthGuard], data: { groups: ['devices'] } },
      { path: '', component: StartComponent, canActivate: [AuthGuard] }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
