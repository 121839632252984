import { Injectable, Output, EventEmitter } from '@angular/core';
import { User } from './user';
import { OAuthService, AuthConfig, TokenResponse, OAuthErrorEvent, OAuthInfoEvent } from 'angular-oauth2-oidc';
import { ConfigService } from './config.service';
import { ConfigDataInterface, MetaConfigInterface } from './config.data';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders } from '@angular/common/http';
import { HttpHelper } from '../utils/http.helper';
import { retry, catchError, map } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import { AlertService } from './alert.service';
import { AuthTokenInterceptor } from './auth.token.interceptor';
import { Router, ActivatedRoute } from '@angular/router';


export interface LoginData {
    clientId: string;
    scopes: string[];
}



@Injectable()
export class AuthenticationService {
    @Output() userChanged: EventEmitter<User> = new EventEmitter();

    private _currentUser: User;

    constructor(private http: HttpClient, private configService: ConfigService, private oauthService: OAuthService, private alert: AlertService,
        private router: Router,
        private route: ActivatedRoute) {

        this.configService.onConfig(() => {
            this.init();
        });
    }

    get config(): ConfigDataInterface {
        return this.configService.config;
    }

    get meta(): MetaConfigInterface {
        return this.configService.meta;
    }

    get baseUrl(): string {
        return this.configService.baseUrlHelper.baseUrl;
    }



    private authConfig: AuthConfig = {
        /**
         * Url of the token endpoint as defined by OpenId Connect and OAuth 2.
         */
        tokenEndpoint: '',

        // The SPA's id. Register SPA with this id at the auth-server
        clientId: '',

        /**
         * The logout url.
         */
        logoutUrl: 'logout',

        // set the scope for the permissions the client should request
        scope: '',

        // Set a dummy secret
        // Please note that the auth-server used here demand the client to transmit a client secret, although
        // the standard explicitly cites that the password flow can also be used without it. Using a client secret
        // does not make sense for a SPA that runs in the browser. That's why the property is called dummyClientSecret
        // Using such a dummy secret is as safe as using no secret.
        dummyClientSecret: '',

        // Url with user info endpoint
        // This endpont is described by OIDC and provides data about the loggin user
        // This sample uses it, because we don't get an id_token when we use the password flow
        // If you don't want this lib to fetch data about the user (e. g. id, name, email) you can skip this line
        //userinfoEndpoint: "https://steyer-identity-server.azurewebsites.net/identity/connect/userinfo",

        /**
         * Defines when the token_timeout event should be raised.
         * If you set this to the default value 0.75, the event
         * is triggered after 75% of the token's life time.
         */
        timeoutFactor: 0.75,

        /**
         * Defines whether additional debug information should
         * be shown at the console. Note that in certain browsers
         * the verbosity of the console needs to be explicitly set
         * to include Debug level messages.
         */
        showDebugInformation: false//BaseUrlHelper.isDevelopment()            
    };




    private static firstInit = true



    public init() {
        const config = this.config;
        const meta = this.meta;

        const authTokenEndpoint = this.configService.authTokenEndpoint;

        if (!meta.id || !authTokenEndpoint) {
            return;
        }


        this.authConfig.tokenEndpoint = authTokenEndpoint;
        this.authConfig.clientId = meta.id;


        // we need to set the scope value correctly, otherwise refreshing the token will result in an access token with no scopes set
        const loginData = this.getCurrentLoginData(true);
        if (loginData) {
            this.authConfig.scope = loginData.scopes.join(' ');
        }

        this.oauthService.configure(this.authConfig);



        this.oauthService.events.subscribe(event => {

            if (event instanceof OAuthErrorEvent) {
                this.alert.error('Authentication error: ' + event.type, true);

            } else if (event instanceof OAuthInfoEvent) {

                if (event.type === 'token_expires') {
                    // based on timeoutFactor

                    //console.log('about to expire', this.oauthService.hasValidAccessToken());
                    this.oauthService.refreshToken();
                }

            } else if (/*event.type == 'token_received' ||*/ event.type === 'token_refreshed') {
                this.loadCurrentUser().subscribe();

                //console.log('new token: ');

                this.checkConfig();
            }
        });



        if (AuthTokenInterceptor.authUrl != '' && (this.oauthService.getAccessToken() && !this.oauthService.hasValidAccessToken())) {
            this.oauthService.refreshToken();
        }

        const self = this

        if (meta.isAutoAuthenticated) {
            this.login('', '').then((token: TokenResponse) => {
                //console.log(token, this.getCurrentLoginData());
                //console.log(token, this.getLoginDataFromToken(token.access_token));

                this.checkConfig()
                    .catch((error: HttpErrorResponse) => {
                    })
                    .then(() => {
                        self.router.navigate(['']);
                    })

                self.loadCurrentUser().subscribe();
            });

        } else {
            // This method just tries to parse the token(s) within the url when
            // the auth-server redirects the user back to the web-app
            // It doesn't send the user the the login page
            this.oauthService.tryLogin({});
            this.loadCurrentUser().subscribe(
                (v) => { // success                
                }, (v) => { // error
                    if (v === 'could not get user info' || AuthenticationService.firstInit) {
                        this.logout()
                    }
                }, () => {// complete
                    AuthenticationService.firstInit = false
                });
        }

        //console.log(this.currentLoginData);
        //this.currentUser.subscribe(user => console.log(user));
    }

    private checkConfig(): Promise<TokenResponse> {
        const loginData = this.getCurrentLoginData(true);
        if (!loginData) {
            return null
        }

        const scope = loginData.scopes.join(' ');

        if (scope === this.authConfig.scope) {
            return null
        }

        this.authConfig.scope = scope
        this.oauthService.configure(this.authConfig);
        return this.oauthService.refreshToken();
    }


    private getLoginDataFromToken(token: string): LoginData {
        if (!token) {
            return null;
        }

        const parts: string[] = token.split('.');
        if (parts.length !== 3) {
            return null;
        }

        try {
            const data = JSON.parse(atob(parts[1]));

            if (data['aud'] && data['scopes']) {
                return {
                    'clientId': data['aud'],
                    'scopes': data['scopes']
                }
            }

        } catch {
        }

        return null;
    }


    private getCurrentLoginData(force = false): LoginData {
        if (!force && !this.oauthService.hasValidAccessToken()) {
            return null;
        }

        return this.getLoginDataFromToken(this.oauthService.getAccessToken());
    }

    public get currentLoginData(): LoginData {
        return this.getCurrentLoginData();
    }

    public hasLoginGroup(group: string): boolean {
        const data = this.currentLoginData;
        if (!data) {
            return false;
        }

        return data.scopes.indexOf(group) !== -1;
    }


    public loadCurrentUser(): Observable<User> {
        if (!this.oauthService.hasValidAccessToken()) {
            return of(null);
        }

        if (this._currentUser) {
            return of(this._currentUser);
        }

        const authInfoEndpoint = this.configService.authInfoEndpoint;
        if (!authInfoEndpoint) {
            return of(null);
        }

        return HttpHelper.load(this.http, authInfoEndpoint).pipe(
            //retry(3), // retry a failed request up to 3 times
            catchError((error: HttpErrorResponse) => { return this.handleError(error, 'could not get user info'); })
        ).pipe(map((user: User) => {
            this._currentUser = user;
            //console.log('userinfo ', user);
            this.userChanged.emit(this._currentUser);
            return user;
        }));
    }

    public reloadCurrentUser(): Observable<User> {
        this._currentUser = null;
        return this.loadCurrentUser();
    }

    public get currentUser(): Observable<User> {
        if (!this.oauthService.hasValidAccessToken()) {
            return null;
        }

        if (!this._currentUser) {
            return null;
        }

        return of(this._currentUser);
    }

    @Output()
    public get currentUserDirect(): User {
        if (!this.oauthService.hasValidAccessToken()) {
            return null;
        }

        if (!this._currentUser) {
            return null;
        }

        return this._currentUser;
    }

    login(username: string, password: string): Promise<TokenResponse> {

        this.oauthService.revokeTokenAndLogout();
        this.oauthService.scope = '';

        return this.oauthService.fetchTokenUsingPasswordFlow(username, password).catch((error: HttpErrorResponse) => {
            this.alert.error('Could not Login', true);
            //console.log(error);
            throw error;

        }).then((token: TokenResponse) => {
            //console.log(token);
            //console.log(this.oauthService.getAccessToken());

            if (token['userinfo']) {
                this._currentUser = token['userinfo'] as any;
                this.userChanged.emit(this._currentUser);

                const url = (this.route.snapshot.queryParams['redirect_url'] ? this.route.snapshot.queryParams['redirect_url'] : ''/*token['redirect_url']*/) || '';
                this.router.navigate([url]);
            }

            // this changes the token again, leading to unauthorized access returns
            //this.checkConfig(); 

            //console.log(this.oauthService.getAccessToken());

            return token;
        });
    }

    public handleError(error: HttpErrorResponse, msg: string): Observable<HttpEvent<any>> {
        this.alert.error(msg, true);

        return throwError(msg);
    }

    logout(): boolean {
        const authLogoutEndpoint = this.configService.authLogoutEndpoint;

        if (authLogoutEndpoint && this.oauthService.hasValidAccessToken()) {
            HttpHelper.load(this.http, authLogoutEndpoint).pipe(
                retry(3), // retry a failed request up to 3 times
                catchError((error: HttpErrorResponse) => { return this.handleError(error, 'could not logout'); })
            ).subscribe((resp: any) => {
                //console.log('logout', resp);
                this.router.navigate(['login']);
            });
        }

        this.oauthService.logOut();
        this._currentUser = null;
        this.userChanged.emit(this._currentUser);

        return true;
    }
}
