import { Component, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormControl, UntypedFormGroup, UntypedFormBuilder, AbstractControl, NgForm } from '@angular/forms';
import { AuthenticationService } from './authentication.service';
import { AlertService } from './alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TokenResponse } from 'angular-oauth2-oidc';

@Component({
    selector: 'lib-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent {
    @Output()
    registerForm: UntypedFormGroup;

    isLoading = false;

    @ViewChild('formDirective') private formDirective: NgForm;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private fb: UntypedFormBuilder
    ) {
        this.registerForm = this.fb.group({
            user: ['', [Validators.required]],
            pass: ['', [Validators.required, Validators.minLength(6)]]
        });

        this.checkLoginStatus();

        this.authenticationService.userChanged.subscribe(_ => {
            this.checkLoginStatus();
        });
    }

    private checkLoginStatus() {
        // redirect to home if already logged in
        if (this.authenticationService.currentLoginData) {

            const url = (this.route.snapshot.queryParams['redirect_url'] ? this.route.snapshot.queryParams['redirect_url'] : ''/*token['redirect_url']*/) || '';

            this.router.navigate([url]);
        }
    }

    @Output()
    get controls(): { [key: string]: AbstractControl } {
        return this.registerForm.controls;
    }

    onSubmit() {
        // reset alerts on submit
        this.alertService.clear();

        if (this.registerForm.invalid) {
            return;
        }

        this.isLoading = true;
        this.authenticationService.login(this.controls.user.value, this.controls.pass.value)
            .catch((error: HttpErrorResponse) => {
                this.isLoading = false;
            }).then((token: TokenResponse) => {
                this.formDirective.resetForm();
                this.router.navigate(['']);
            });
    }
}