import { Component, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Service } from './app.service';
import { AuthenticationService } from 'common/lib/auth/authentication.service';
import { UserInfoComponent } from 'common/lib/auth/userinfo.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @Output()
  userinfo: UserInfoComponent;

  constructor(private authService: AuthenticationService, public service: Service, private route: ActivatedRoute, public dialog: MatDialog) {
    this.userinfo = new UserInfoComponent(authService);

    // console.log(this.route.params['token']);
  }


  openOptions() {
    const dialogRef = this.dialog.open(DialogOptionsComponent, {
      width: '450px',
      data: {
        service: this.service/*,
        timezone: this.service.timezone*/
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (!data || data.length <= 0) {
        return;
      }

      // this.service.timezone = data.timezone;
    });
  }

}

export interface DialogData {
  service: Service;
  //timezone: string;
}

@Component({
  selector: 'app-options',
  templateUrl: 'app.options.component.html'
})
export class DialogOptionsComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
