import { Injectable, Output, Inject, InjectionToken } from '@angular/core';
import { HttpHelper } from '../utils/http.helper';
import { HttpClient, HttpErrorResponse, HttpParams, HttpEvent } from '@angular/common/http';
import { ConfigData, ConfigDataInterface, MetaConfigInterface, MetaConfig } from './config.data';
import { BaseUrlHelper } from '../utils/baseurl.helper';
import { catchError, retry } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { AlertService } from './alert.service';
import { AuthTokenInterceptor } from './auth.token.interceptor';



export interface AuthModuleConfig {
    oauthClient: string;
}

export const AuthModuleConfigService = new InjectionToken<AuthModuleConfig>("AuthModuleConfig");



@Injectable()
export class ConfigService {
    static offlineDebug = false;

    static initialized: boolean = false;
    static initCallbacks: (() => void)[] = [];


    private _baseUrlHelper = new BaseUrlHelper();

    @Output()
    public error: string;

    @Output()
    public config: ConfigDataInterface;

    @Output()
    public meta: MetaConfigInterface;

    @Output()
    get baseUrlHelper(): BaseUrlHelper {
        return this._baseUrlHelper;
    }

    constructor(public http: HttpClient, private alert: AlertService, @Inject(AuthModuleConfigService) private authConfig) {
        this.config = new ConfigData;
        this.meta = new MetaConfig;


        if (!BaseUrlHelper.isDevelopment()) {
            this._baseUrlHelper.init();

            this.config.apiBaseUrl = this._baseUrlHelper.baseUrl;
            this.loadMeta();

        } else {
            this._baseUrlHelper.init(this.config.apiBaseUrl);

            HttpHelper.load(this.http, '/assets/config.json').pipe(
                retry(3), // retry a failed request up to 3 times
                catchError((error: HttpErrorResponse) => { return this.handleError(error, 'could not load config.json'); })
            ).subscribe((resp: ConfigDataInterface) => {
                this.config = resp;

                if (resp && resp.apiBaseUrl) {
                    this._baseUrlHelper.init(resp.apiBaseUrl);
                }
                this.loadMeta();
            });
        }
    }

    public handleError(error: HttpErrorResponse, msg: string): Observable<HttpEvent<any>> {
        this.error = msg;

        this.alert.error(this.error, true);

        return throwError(this.error);
    }

    get metaUrl(): string {
        return this.config.apiBaseUrl + 'client_' + this.authConfig.oauthClient + '/meta/';
    }

    private doInitCallback() {
        AuthTokenInterceptor.authUrl = this.meta.authEndpoint;
        ConfigService.initialized = true;

        for (const cb of ConfigService.initCallbacks) {
            cb();
        }

        ConfigService.initCallbacks = [];
    }

    public loadMeta() {
        if (ConfigService.offlineDebug) {
            this.doInitCallback();
            return;
        }

        AuthTokenInterceptor.baseUrl = this.baseUrl;


        HttpHelper.load(this.http, this.metaUrl).pipe(
            retry(3), // retry a failed request up to 3 times
            catchError((error: HttpErrorResponse) => { return this.handleError(error, 'could not load meta config'); })
        ).subscribe((resp: MetaConfigInterface) => {
            this.meta = resp;

            this.doInitCallback();
        });
    }

    public onConfig(cb: () => void) {
        if (ConfigService.initialized) {
            cb();
        } else {
            ConfigService.initCallbacks.push(cb);
        }
    }

    get baseUrl(): string {
        return this.baseUrlHelper.baseUrl;
    }

    get authTokenEndpoint(): string {
        if (!this.meta || !this.meta.authEndpoint) {
            return null;
        }
        return this.meta.authEndpoint + 'access_token';
    }

    get authLogoutEndpoint(): string {
        if (!this.meta || !this.meta.authEndpoint) {
            return null;
        }
        return this.meta.authEndpoint + 'logout';
    }

    get authInfoEndpoint(): string {
        if (!this.meta || !this.meta.authEndpoint) {
            return null;
        }
        return this.meta.authEndpoint + 'info';
    }
}