<div *ngIf="isAuthorized">
    <mat-form-field appearance="outline">
        <mat-label i18n>Server</mat-label>
        <mat-select required #server>
            <mat-option *ngFor="let s of servers" [value]="s">{{ s.location }} ({{ s.cn }})</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-slide-toggle #allTraffic [checked]="true">All Traffic</mat-slide-toggle>

    <button mat-icon-button color="primary" (click)="showQrCode(server.value, allTraffic.checked)"
        matTooltip="Show QrCode" [disabled]="!server.value">
        <mat-icon>qr_code_scanner</mat-icon>
    </button>

    <button mat-icon-button color="primary" (click)="downloadConfig(server.value, allTraffic.checked)"
        matTooltip="Download Config" [disabled]="!server.value">
        <mat-icon>cloud_download</mat-icon>
    </button>
</div>