import { Component, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'common/lib/auth/authentication.service';
import { AlertService } from 'common/lib/auth/alert.service';


@Component({
    selector: 'app-start',
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.css']
})
export class StartComponent {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) {

        this.checkLoginStatus();

        this.authenticationService.userChanged.subscribe(_ => {
            this.checkLoginStatus();
        });
    }

    private checkLoginStatus() {
        // redirect to login if already logged in
        if (!this.authenticationService.currentLoginData) {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['login'], { queryParams: { redirect_url: this.router.routerState.snapshot.url } });
        }

        this.router.navigate([
            //this.authenticationService.currentLoginData.scopes.indexOf('useradmin') === -1 ? 'intranet/profile' : 'intranet/users'
            'intranet/profile'
        ]);
    }


}