import { BaseUrlHelper } from '../utils/baseurl.helper';
import { Injectable } from '@angular/core';


export interface ConfigDataInterface {
    apiBaseUrl: string;
}

export interface MetaConfigInterface {
    name: string;
    id: string;
    description: string;
    authEndpoint: string;
    mainDomain: string;
    url: string;
    isAutoAuthenticated: boolean;
}



export class ConfigData implements ConfigDataInterface {
    apiBaseUrl: 'http://localhost/';//api/';
}

export class MetaConfig implements MetaConfigInterface {
    name: string = '<undefined>';
    id: string = '<undefined>';
    description: string = '<undefined>';
    authEndpoint: string = null;
    mainDomain: string;
    url: string;
    isAutoAuthenticated: boolean;
}

