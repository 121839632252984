import { HttpParams, HttpClient, HttpParameterCodec } from '@angular/common/http';
import { Observable } from 'rxjs';


function standardEncoding(v: string): string {

    return encodeURIComponent(v)
        .replace(/%40/gi, '@')
        .replace(/%3A/gi, ':')
        .replace(/%24/gi, '$')
        .replace(/%2C/gi, ',')
        .replace(/%3B/gi, ';')
        .replace(/%2B/gi, '+')
        .replace(/%3D/gi, '=')
        .replace(/%3F/gi, '?')
        .replace(/%2F/gi, '/');
}

export class HttpUrlStandardEncodingCodec implements HttpParameterCodec {
    encodeKey(key: string): string { return standardEncoding(key); }

    encodeValue(value: string): string { return standardEncoding(value); }

    decodeKey(key: string): string { return decodeURIComponent(key); }

    decodeValue(value: string) { return decodeURIComponent(value); }
}

export class HttpUrlEncodingCodec implements HttpParameterCodec {
    encodeKey(key: string): string { return encodeURIComponent(key); }

    encodeValue(value: string): string { return encodeURIComponent(value); }

    decodeKey(key: string): string { return decodeURIComponent(key); }

    decodeValue(value: string) { return decodeURIComponent(value); }
}

export enum ResponseType {
    JSON,
    Text,
    Blob,
    ArrayBuffer
}

export class HttpHelper {

    // static standardEncoder = new HttpUrlEncodingCodec();
    static defaultEncoder = new HttpUrlEncodingCodec();


    static loadJson(http: HttpClient, url: string, parameters?: HttpParams | { [param: string]: string | string[] }, body?: any):
        Observable<Object> {
        if (body !== undefined) {
            return http.post(url, body, {
                params: parameters,
                responseType: 'json'
            });

        } else {
            return http.get(url, {
                params: parameters,
                responseType: 'json'
            });
        }
    }

    static loadText(http: HttpClient, url: string, parameters?: HttpParams | { [param: string]: string | string[] }, body?: any):
        Observable<string> {
        if (body !== undefined) {
            return http.post(url, body, {
                params: parameters,
                responseType: 'text'
            });

        } else {
            return http.get(url, {
                params: parameters,
                responseType: 'text'
            });
        }
    }

    static loadBlob(http: HttpClient, url: string, parameters?: HttpParams | { [param: string]: string | string[] }, body?: any):
        Observable<Blob> {
        if (body !== undefined) {
            return http.post(url, body, {
                params: parameters,
                responseType: 'blob'
            });

        } else {
            return http.get(url, {
                params: parameters,
                responseType: 'blob'
            });
        }
    }

    static loadArrayBuffer(http: HttpClient, url: string, parameters?: HttpParams | { [param: string]: string | string[] }, body?: any):
        Observable<ArrayBuffer> {
        if (body !== undefined) {
            return http.post(url, body, {
                params: parameters,
                responseType: 'arraybuffer'
            });

        } else {
            return http.get(url, {
                params: parameters,
                responseType: 'arraybuffer'
            });
        }
    }

    static load(http: HttpClient, url: string, parameters?: HttpParams | { [param: string]: string | string[] },
        responseType = ResponseType.JSON): Observable<Object> {


        const loadInt = (params?: HttpParams | { [param: string]: string | string[] }) => {
            switch (responseType) {
                case ResponseType.JSON:
                    return HttpHelper.loadJson(http, url, params);
                case ResponseType.Text:
                    return HttpHelper.loadText(http, url, params);
                case ResponseType.Blob:
                    return HttpHelper.loadBlob(http, url, params);
                case ResponseType.ArrayBuffer:
                    return HttpHelper.loadArrayBuffer(http, url, params);
            }
        };


        if (!!parameters && !(parameters instanceof HttpParams)) {
            if (parameters instanceof String) {
                return loadInt(new HttpParams({
                    fromString: parameters + '',
                    encoder: HttpHelper.defaultEncoder
                }));

            } else {
                return loadInt(new HttpParams({
                    fromObject: parameters,
                    encoder: HttpHelper.defaultEncoder
                }));
            }
        } else {
            return loadInt(parameters);
        }
    }








    static upload(http: HttpClient, url: string, body: any, parameters?: HttpParams | { [param: string]: string | string[] },
        responseType = ResponseType.JSON): Observable<Object> {


        const uploadInt = (params?: HttpParams | { [param: string]: string | string[] }) => {
            switch (responseType) {
                case ResponseType.JSON:
                    return HttpHelper.loadJson(http, url, params, body);
                case ResponseType.Text:
                    return HttpHelper.loadText(http, url, params, body);
                case ResponseType.Blob:
                    return HttpHelper.loadBlob(http, url, params, body);
                case ResponseType.ArrayBuffer:
                    return HttpHelper.loadArrayBuffer(http, url, params, body);
            }
        };


        if (!!parameters && !(parameters instanceof HttpParams)) {
            if (parameters instanceof String) {
                return uploadInt(new HttpParams({
                    fromString: parameters + '',
                    encoder: HttpHelper.defaultEncoder
                }));

            } else {
                return uploadInt(new HttpParams({
                    fromObject: parameters,
                    encoder: HttpHelper.defaultEncoder
                }));
            }
        } else {
            return uploadInt(parameters);
        }
    }
}
