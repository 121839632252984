import { Component, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'common/lib/auth/authentication.service';
import { User } from 'common/lib/auth/user';
import { Validators, UntypedFormGroup, UntypedFormBuilder, AbstractControl, NgForm } from '@angular/forms';
import { AlertService } from 'common/lib/auth/alert.service';
import { MustMatchValidator } from 'common/lib/auth/validator.must.match';
import { ConfigService } from 'common/lib/auth/config.service';
import { HttpHelper } from 'common/lib/utils/http.helper';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { FileUploadValidators } from '@iplab/ngx-file-upload';


class Group {
    name: string;
    description: string;
}


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent {
    @Output()
    registerForm: UntypedFormGroup;

    groupColumns: string[] = ['name', 'description'];

    isLoading = false;


    @ViewChild('formDirective') private formDirective: NgForm;


    constructor(
        private http: HttpClient,
        private alert: AlertService,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private config: ConfigService,
        private fb: UntypedFormBuilder
    ) {

        this.registerForm = this.fb.group({
            reset_photo: ['', []],
            surname: ['', [Validators.required]],
            givenname: ['', [Validators.required]],
            password: ['', [/*Validators.required,*/ Validators.minLength(6)]],
            confirmPassword: ['', [/*Validators.required,*/ Validators.minLength(6)]],
            email: ['', [Validators.required, Validators.email]],
            files: ['', [/*Validators.required, requiredFileType('png')*/FileUploadValidators.filesLimit(1)]]
        }, {
            validator: MustMatchValidator('password', 'confirmPassword')
        });

        this.checkLoginStatus();

        this.authenticationService.userChanged.subscribe(_ => {
            this.checkLoginStatus();
        });
    }

    private checkLoginStatus() {
        this.setUserData(this.authenticationService.currentUserDirect);

        // redirect to login if already logged in
        if (!this.authenticationService.currentLoginData) {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['login'], { queryParams: { redirect_url: this.router.routerState.snapshot.url } });
        }
    }


    @Output()
    get user(): User {
        return this.authenticationService.currentUserDirect || new User();
    }

    @Output()
    get isAdministrativeUser(): boolean {
        const user = this.user;
        return user && !user.isUser;
    }

    @Output()
    public isInGroup(group: string): boolean {
        const user = this.user;
        if (!user || !user.groups) {
            return false
        }
        return user.groups[group] !== undefined;
    }

    @Output()
    get controls(): { [key: string]: AbstractControl } {
        return this.registerForm.controls;
    }

    @Output()
    get groups(): Group[] {
        const res: Group[] = [];

        const user = this.authenticationService.currentUserDirect;
        if (user) {
            for (const g in user.groups) {
                if (user.groups.hasOwnProperty(g)) {
                    const description = user.groups[g];

                    res.push({
                        name: g,
                        description: description
                    })
                }
            }
        }

        return res;
    }

    public setUserData(user: User) {
        if (!user) {
            return;
        }

        this.controls.givenname.setValue(user.gn);
        this.controls.surname.setValue(user.sn);
        this.controls.email.setValue(user.mail);
        this.controls.files.reset();
        this.controls.reset_photo.reset();
    }

    @Output()
    get mainDomain(): string {
        return this.config.meta.mainDomain;
    }


    get usersEndpoint(): string {
        if (!this.config.meta || !this.config.meta.authEndpoint) {
            return null;
        }
        return this.config.meta.authEndpoint + 'users/';
    }

    public getUserCmdEndpoint(user: string, cmd: string): string {
        const url = this.usersEndpoint;
        if (!url) {
            return null;
        }
        return url + user + '/' + cmd;
    }

    public update() {
        const url = this.router.url

        this.authenticationService.reloadCurrentUser().subscribe((user: User) => {
            this.setUserData(user)
            this.router.navigate([url])
        });
    }

    onSubmit() {
        // reset alerts on submit
        this.alert.clear();

        if (this.registerForm.invalid) {
            return;
        }


        const sn = (this.controls.surname.value || '') as string;
        const gn = (this.controls.givenname.value || '') as string;


        const user = this.user.id;


        let mail = (this.controls.email.value || '') as string;
        if (!mail || mail.length < 1) {
            mail = user + '@' + this.mainDomain;
        }



        var data = new FormData();
        if (((this.controls.password.value || '') as string) != '') {
            data.append('password', this.controls.password.value as string);
        }
        data.append('sn', sn);
        data.append('givenName', gn);
        data.append('cn', gn + ' ' + sn);
        data.append('mail', mail);


        if (this.controls.reset_photo.value as boolean) {
            data.append('photo', '');

        } else {
            const files = this.controls.files.value as File[] || []
            if (files && files.length > 0) {
                data.append('photo', files[0], files[0].name);
            }
        }


        const url = this.getUserCmdEndpoint(user, 'change_value');




        this.isLoading = true;
        HttpHelper.upload(this.http, url, data).pipe(
            catchError((error: HttpErrorResponse) => { return this.handleError(error, 'could not change user'); })
        ).subscribe((resp: any) => {
            this.isLoading = false;

            if (resp.status !== 'success') {
                this.alert.error('could not change user');
                return;
            }

            this.formDirective.resetForm();
            this.update();
        });
    }

    public handleError(error: HttpErrorResponse, msg: string): Observable<HttpEvent<any>> {
        this.alert.error(msg, true);
        this.isLoading = false;

        return throwError(msg);
    }
}