import { Component, Output, Input, EventEmitter } from '@angular/core';
import { User, CertificateInfo } from 'common/lib/auth/user';
import { ConfirmationWithPasswordDialog } from '../password/confirm.with.password.options.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'common/lib/auth/alert.service';
import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { HttpHelper } from 'common/lib/utils/http.helper';
import { catchError } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { Observable, throwError } from 'rxjs';
import { ConfigService } from 'common/lib/auth/config.service';

@Component({
    selector: 'app-certinfo',
    templateUrl: './certinfo.component.html',
    styleUrls: ['./certinfo.component.css']
})
export class CertificateInfoComponent {

    private _user: User;

    @Output()
    currentTime: number;

    @Output()
    change = new EventEmitter();

    constructor(
        private http: HttpClient,
        private alert: AlertService,
        private config: ConfigService,
        private confirm: MatDialog) {
        this.currentTime = +(new Date());//+ 300 * 86400*1000;
    }

    @Input()
    set user(user: User) {
        this._user = user;
    }

    get user(): User {
        return this._user;
    }

    get id(): string {
        if (!this._user || !this._user.id) {
            return null;
        }
        return this._user.id;
    }

    formateDate(timestamp: number): string {
        return (new Date(timestamp)).toDateString();
    }

    @Output()
    get certificate(): CertificateInfo {
        if (!this._user || !this._user.certificate) {
            return null;
        }
        return this._user.certificate;
    }


    get usersEndpoint(): string {
        if (!this.config.meta || !this.config.meta.authEndpoint) {
            return null;
        }
        return this.config.meta.authEndpoint + 'users/';
    }

    public getUserCmdEndpoint(user: string, cmd: string): string {
        const url = this.usersEndpoint;
        if (!url) {
            return null;
        }
        return url + user + '/' + cmd;
    }

    public recreateCert() {
        const url = this.getUserCmdEndpoint(this.id, 'create_cert');
        if (!url) {
            return;
        }


        const dialogRef = this.confirm.open(ConfirmationWithPasswordDialog, {
            width: '350px',
            data: {
                title: 'Create New Certificate', message: 'Do you really want to create a new certificate for the user "' + this.id +
                    '"? You might need to distribute new configurations to all clients. The user password is required to store the certificate encrypted.',
                password: ''
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var data = new FormData();
                data.append('password', result.password);

                //this.isLoading = true;
                HttpHelper.upload(this.http, url, data).pipe(
                    catchError((error: HttpErrorResponse) => { return this.handleError(error, 'could not create certificate'); })
                ).subscribe((resp: any) => {
                    //this.isLoading = false;

                    if (resp.status !== 'success') {
                        this.alert.error('could not create certificate');
                        return;
                    }

                    this.change.emit();
                });
            }
        });
    }

    public downloadCert() {
        const url = this.getUserCmdEndpoint(this.id, 'downloadPkcs12');
        if (!url) {
            return;
        }


        HttpHelper.load(this.http, url).pipe(
            catchError((error: HttpErrorResponse) => { return this.handleError(error, 'could not download certificate'); })
        ).subscribe((resp: any) => {
            if (resp.status !== 'success') {
                this.alert.error('could not download certificate');
                return;
            }


            const bytes = atob(resp.data['usercertificatepkcs12']);

            var byteNumbers = new Array(bytes.length);
            for (var i = 0; i < bytes.length; i++) {
                byteNumbers[i] = bytes.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);

            var blob = new Blob([byteArray], { type: "application/x-pkcs12" });//; charset=utf-8" });
            saveAs(blob, this.id + '.p12');//, { autoBom: true });
        });
    }

    public handleError(error: HttpErrorResponse, msg: string): Observable<HttpEvent<any>> {
        this.alert.error(msg, true);
        //this.isLoading = false;

        return throwError(msg);
    }
}
