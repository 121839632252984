<div *ngIf="isAuthorized">
    <mat-form-field appearance="outline">
        <mat-label>Options</mat-label>
        <mat-select #options multiple [(value)]="selectedProfileTypes">
            <mat-option *ngFor="let p of profileTypes" [value]="p.key">{{p.title}}</mat-option>
        </mat-select>
    </mat-form-field>

    <button mat-icon-button color="primary" (click)="downloadConfig('mobileconfig')" matTooltip="Download Config">
        <mat-icon>cloud_download</mat-icon>
    </button>
</div>