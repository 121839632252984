import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard  {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentLogin = this.authenticationService.currentLoginData;
        if (currentLogin) {
            if (route.data['groups']) {
                for (const g of route.data['groups']) {
                    if (currentLogin.scopes.indexOf(g) === -1) {
                        console.error('you are not allowed to access this resource');

                        // not logged in so redirect to login page with the return url
                        this.router.navigate(['profile']);
                        return false;
                    }
                }
            }

            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['login'], { queryParams: { redirect_url: state.url } });
        return false;
    }
}
