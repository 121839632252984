<form [formGroup]="registerForm" (ngSubmit)="onSubmit()" #formDirective="ngForm" *ngIf="editId">
    <mat-card class="profile-card">
        <mat-card-header>
            <mat-card-title>{{ editId === 'new' ? 'Create User' : 'Change User "' + editUsername + '"' }}
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <mat-form-field appearance="outline">
                <mat-slide-toggle formControlName="activated">Active</mat-slide-toggle>
                <textarea matInput hidden></textarea>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-slide-toggle formControlName="simpleRole" (change)="accountTypeChanged(toggleVisbility);">
                    Service Account (e.g. only for Mail Forwarding)</mat-slide-toggle>
                <textarea matInput hidden></textarea>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label i18n>Username</mat-label>
                <input matInput formControlName="username" required [readonly]="editId !== 'new'">
                <mat-error *ngIf="controls.username.hasError('required')">
                    Username is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="controls.username.hasError('minlength')">
                    Username is <strong>too short</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label i18n>New Password</mat-label>
                <!--<mat-pass-toggle-visibility #toggleVisbility matSuffix></mat-pass-toggle-visibility>
                <button type="button" matSuffix mat-icon-button aria-label="Generate"
                    (click)="toggleVisbility.isVisible=true; generatePassword()">
                    <mat-icon>star_rate</mat-icon>
                </button>-->
                <input matInput [type]="toggleVisbility.type" formControlName="password" #password>
                <!--[required]="editId === 'new'" minlength="6">-->
                <mat-error *ngIf="controls.password.hasError('minlength')">
                    Password is <strong>too short</strong>
                </mat-error>
                <mat-error *ngIf="controls.password.hasError('required')">
                    Password is <strong>required</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label i18n>Confirm Password</mat-label>
                <input matInput type="password" formControlName="confirmPassword" #passsword2>
                <!--[required]="editId === 'new'" minlength="6">-->
                <mat-error *ngIf="controls.confirmPassword.hasError('minlength')">
                    Password is <strong>too short</strong>
                </mat-error>
                <mat-error *ngIf="controls.confirmPassword.hasError('mustmatch')">
                    Passwords must <strong>match</strong>
                </mat-error>
                <mat-error *ngIf="controls.confirmPassword.hasError('required')">
                    Password is <strong>required</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label i18n>Given Name</mat-label>
                <input matInput formControlName="givenname" [required]="this.nameIsRequired">
                <mat-error *ngIf="controls.givenname.hasError('required')">
                    Given Name is <strong>required</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label i18n>Surname</mat-label>
                <input matInput formControlName="surname" [required]="this.nameIsRequired">
                <mat-error *ngIf="controls.surname.hasError('required')">
                    Surname is <strong>required</strong>
                </mat-error>
            </mat-form-field>

            <div>
                <h4>Profile Picture</h4>
                <mat-slide-toggle *ngIf="editId !== 'new'" formControlName="reset_photo" #reset_photo>Remove Image</mat-slide-toggle>
                <file-upload formControlName="files" *ngIf="editId === 'new' || !controls.reset_photo.value" fileslimit="1" [multiple]="false" minsize="0 MB" maxsize="2 MB"
                    [accept]="'image/*'"></file-upload>
                <img *ngIf="editId !== 'new'" [src]="usersEndpoint + editId + '/photo' | securedimage | async" />
            </div>

            <mat-form-field appearance="outline">
                <mat-label i18n>Groups</mat-label>
                <mat-select formControlName="groups" multiple>
                    <mat-option *ngFor="let g of allGroups | async" [value]="g.dn">{{ g.description }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label i18n>E-Mail</mat-label>
                <input matInput type="email" formControlName="email" [required]="this.emailIsRequired">
                <mat-error *ngIf="controls.email.hasError('required')">
                    E-Mail is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="controls.email.hasError('email')">
                    E-Mail is <strong>invalid</strong>
                </mat-error>
            </mat-form-field>

            <div formArrayName="aliases">
                <mat-form-field appearance="outline" *ngFor="let p of this.aliases.controls; let i = index"
                    [formGroupName]="i">
                    <mat-label i18n>Alias</mat-label>
                    <input matInput type="email" formControlName="email" required>
                    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="removeAlias(i)">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="$any(this.aliases.at(i)).controls.email.hasError('required')">
                        Alias is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="$any(this.aliases.at(i)).controls.email.hasError('pattern')">
                        Alias is <strong>invalid</strong>
                    </mat-error>
                </mat-form-field>

                <button mat-stroked-button color="primary" (click)="addAlias()">
                    <mat-icon>add</mat-icon>
                    Add Alias
                </button>
            </div>

            <div formArrayName="forwardings">
                <mat-form-field appearance="outline" *ngFor="let p of this.forwardings.controls; let i = index"
                    [formGroupName]="i">
                    <mat-label i18n>Forwarding</mat-label>
                    <input matInput type="email" formControlName="email" required>
                    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="removeForwarding(i)">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="$any(this.forwardings.at(i)).controls.email.hasError('required')">
                        Forwarding is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="$any(this.forwardings.at(i)).controls.email.hasError('pattern')">
                        Forwarding is <strong>invalid</strong>
                    </mat-error>
                </mat-form-field>

                <button mat-stroked-button color="primary" (click)="addForwarding()">
                    <mat-icon>add</mat-icon>
                    Add Forwarding
                </button>
            </div>

            <mat-form-field appearance="outline">
                <mat-label i18n>Description</mat-label>
                <textarea matInput formControlName="desc" cdkTextareaAutosize></textarea>
            </mat-form-field>

        </mat-card-content>

        <mat-card-actions>
            <button mat-raised-button type="submit" value="Submit" color="primary"
                [disabled]="isLoading || registerForm.invalid">{{ editId === 'new' ? 'New' : 'Change' }}</button>

            <button mat-stroked-button value="Submit" color="primary" (click)="resetEdit()">Cancel</button>
        </mat-card-actions>
    </mat-card>
</form>

<form #formDirective="ngForm" *ngIf="!editId">
    <mat-card class="profile-card">
        <mat-card-header>
            <mat-card-title>Users</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <mat-spinner *ngIf="isLoading"></mat-spinner>

            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput #filter (keyup)="applyFilter($event)" placeholder="e.g. Username">
                <button mat-button *ngIf="filter.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="filter.value = ''; applyFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <table mat-table [dataSource]="filteredUsers" class="users-table" matSort matSortActive="id"
                matSortDisableClear matSortDirection="asc" style="width: 100%;" multiTemplateDataRows>

                <ng-container matColumnDef="deactivated">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Activated</th>
                    <ng-container *matCellDef="let row">
                        <td mat-cell *ngIf="editId === row.id; else actLabel">
                            <mat-slide-toggle formControlName="activated"></mat-slide-toggle>
                        </td>
                        <ng-template #actLabel>
                            <td mat-cell>
                                <mat-slide-toggle [checked]="!row.deactivated"
                                    (change)="toggleActivation(row.id, $event)">
                                </mat-slide-toggle>
                                <!--<button mat-icon-button color="primary" aria-label="Toggle Activation"
                                    [disabled]="editId" (click)="toggleActivation(row.id)">
                                    <mat-icon>{{row.deactivated ? 'block' : 'check'}}</mat-icon>
                                </button>-->

                                <img [src]="usersEndpoint + row.id + '/photo' | securedimage | async" />
                            </td>
                        </ng-template>
                    </ng-container>
                </ng-container>

                <ng-container matColumnDef="simpleRole">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                    <ng-container *matCellDef="let row">
                        <td mat-cell *ngIf="editId === row.id; else roleLabel">
                            <mat-slide-toggle formControlName="simpleRole"></mat-slide-toggle>
                        </td>
                        <ng-template #roleLabel>
                            <td mat-cell>
                                <mat-icon>{{ row.isSimpleRole ? 'no_accounts' : 'account_circle' }}</mat-icon>
                            </td>
                        </ng-template>
                    </ng-container>
                </ng-container>

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>User</th>
                    <ng-container *matCellDef="let row">
                        <td mat-cell *ngIf="editId === row.id; else idLabel">
                            <mat-form-field appearance="outline">
                                <mat-label i18n>Username</mat-label>
                                <input matInput formControlName="username" required>
                                <mat-error *ngIf="controls.username.hasError('required')">
                                    Username is <strong>required</strong>
                                </mat-error>
                                <mat-error *ngIf="controls.username.hasError('minlength')">
                                    Username is <strong>too short</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <ng-template #idLabel>
                            <td mat-cell>
                                {{ row.id }}
                            </td>
                        </ng-template>
                    </ng-container>
                </ng-container>

                <ng-container matColumnDef="sn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Surname</th>
                    <ng-container *matCellDef="let row">
                        <td mat-cell *ngIf="editId === row.id; else snLabel">
                            <mat-form-field appearance="outline">
                                <mat-label i18n>Surname</mat-label>
                                <input matInput formControlName="surname" required>
                                <mat-error *ngIf="controls.surname.hasError('required')">
                                    Surname is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <ng-template #snLabel>
                            <td mat-cell>
                                {{ row.sn }}
                            </td>
                        </ng-template>
                    </ng-container>
                </ng-container>

                <ng-container matColumnDef="gn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Given Name</th>
                    <ng-container *matCellDef="let row">
                        <td mat-cell *ngIf="editId === row.id; else gnLabel">
                            <mat-form-field appearance="outline">
                                <mat-label i18n>Given Name</mat-label>
                                <input matInput formControlName="givenname" required>
                                <mat-error *ngIf="controls.givenname.hasError('required')">
                                    Given Name is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <ng-template #gnLabel>
                            <td mat-cell>
                                {{ row.gn }}
                            </td>
                        </ng-template>
                    </ng-container>
                </ng-container>

                <ng-container matColumnDef="mail">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mail</th>
                    <ng-container *matCellDef="let row">
                        <td mat-cell *ngIf="editId === row.id; else mailLabel">
                            <mat-form-field appearance="outline">
                                <mat-label i18n>E-Mail</mat-label>
                                <input matInput type="email" formControlName="email" required>
                                <mat-error *ngIf="controls.email.hasError('required')">
                                    E-Mail is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <ng-template #mailLabel>
                            <td mat-cell>
                                {{ row.mail }}
                            </td>
                        </ng-template>
                    </ng-container>
                </ng-container>

                <ng-container matColumnDef="desc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                    <ng-container *matCellDef="let row">
                        <td mat-cell *ngIf="editId === row.id; else descLabel">
                            <mat-form-field appearance="outline">
                                <mat-label i18n>Description</mat-label>
                                <input matInput formControlName="desc" required>
                            </mat-form-field>
                        </td>
                        <ng-template #descLabel>
                            <td mat-cell>
                                {{ row.desc }}
                            </td>
                        </ng-template>
                    </ng-container>
                </ng-container>

                <ng-container matColumnDef="cmds">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Commands</th>
                    <ng-container *matCellDef="let row">
                        <td mat-cell *ngIf="editId === row.id; else cmdsLabel">
                            <button mat-icon-button color="primary" aria-label="Cancel Edit" (click)="resetEdit()">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </td>
                        <ng-template #cmdsLabel>
                            <td mat-cell>
                                <button *ngIf="!editId" mat-icon-button color="primary" aria-label="Edit User"
                                    (click)="editUser(row.id)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button *ngIf="!editId" mat-icon-button color="primary" aria-label="Delete User"
                                    (click)="deleteUser(row.id)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-template>
                    </ng-container>
                </ng-container>







                <ng-container matColumnDef="detail0">
                    <td mat-cell *matCellDef="let row" [attr.colspan]="3">
                        <div *ngIf="row.certificate" class="details mat-elevation-z3">
                            <mat-label i18n>Certificate:</mat-label>
                            <app-certinfo [user]="row" (change)="update()"></app-certinfo>
                        </div>
                        <div *ngIf="row.groups['vpn']" class="details mat-elevation-z3">
                            <mat-label i18n>VPN:</mat-label>
                            <app-vpninfo [user]="row"></app-vpninfo>
                        </div>
                        <div *ngIf="row.groups['mailbox'] || row.groups['addressbook'] || row.groups['calendar'] || row.groups['vpn'] || row.groups['wlan'] || row.groups['wlan-guests']"
                            class="details mat-elevation-z3">
                            <mat-label i18n>Profile Config:</mat-label>
                            <app-profile-config [user]="row"></app-profile-config>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="detail1">
                    <td mat-cell *matCellDef="let row" [attr.colspan]="2">
                        <div *ngIf="row.groupids.length > 0" class="details mat-elevation-z3">
                            <mat-label i18n>Member Of:</mat-label>
                            <ul>
                                <li *ngFor="let group of row.groupids">{{ row.groups[group].description }}</li>
                            </ul>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="detail2">
                    <td mat-cell *matCellDef="let row" [attr.colspan]="2">
                        <div *ngIf="row.mailAliases.length > 0" class="details mat-elevation-z3">
                            <mat-label i18n>Aliases:</mat-label>
                            <ul>
                                <li *ngFor="let m of row.mailAliases">{{ m }}</li>
                            </ul>
                        </div>
                        <div *ngIf="row.mailForwardings.length > 0">
                            <mat-label i18n>Forwardings:</mat-label>
                            <ul>
                                <li *ngFor="let m of row.mailForwardings">{{ m }}</li>
                            </ul>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="detail3">
                    <td mat-cell *matCellDef="let row"></td>
                </ng-container>





                <tr mat-header-row *matHeaderRowDef="usersColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: usersColumns;"
                    [class]="['mainRow', row.deactivated || editId ? 'inactive' : 'active', row.isSimpleRole ? 'simple': 'user']">
                </tr>

                <tr mat-row *matRowDef="let row; columns: ['detail0', 'detail1', 'detail2', 'detail3']"
                    [class]="['detailRow', row.deactivated || editId ? 'inactive' : 'active', row.isSimpleRole ? 'simple': 'user']">
                </tr>
            </table>

            <mat-paginator [length]="numberOfUsers" pageSize="10" [pageSizeOptions]="[5, 10, 20, 50, 100]"
                showFirstLastButtons>
            </mat-paginator>
        </mat-card-content>

        <mat-card-actions>
            <button mat-stroked-button color="primary" (click)="createUser()">
                <mat-icon>person_add</mat-icon>
                Create User
            </button>
        </mat-card-actions>
    </mat-card>
</form>