import { Component, Output, Input, LOCALE_ID, Inject } from '@angular/core';
import { User } from 'common/lib/auth/user';
import { AlertService } from 'common/lib/auth/alert.service';
import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { HttpHelper } from 'common/lib/utils/http.helper';
import { catchError, map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { Observable, throwError } from 'rxjs';
import { ConfigService } from 'common/lib/auth/config.service';
import { QrCodeDialog } from '../qrcode/qrcode.options.component';
import { MatDialog } from '@angular/material/dialog';


class Server {
    id: string;
    cn: string;
    location: string;
}

@Component({
    selector: 'app-vpninfo',
    templateUrl: './vpninfo.component.html',
    styleUrls: ['./vpninfo.component.css']
})
export class VpnInfoComponent {

    private _user: User;
    private _servers: Server[] = null

    constructor(
        private http: HttpClient,
        private alert: AlertService,
        private config: ConfigService,
        private confirm: MatDialog,
        @Inject(LOCALE_ID) public locale: string) {
    }

    @Input()
    set user(user: User) {
        this._user = user;
    }

    get user(): User {
        return this._user;
    }

    get id(): string {
        if (!this._user || !this._user.id) {
            return null;
        }
        return this._user.id;
    }

    @Output()
    get isAuthorized(): boolean {
        const user = this.user;
        if (!user || !user.groups) {
            return false
        }
        return user.groups['vpn'] !== undefined;
    }


    get apiEndpoint(): string {
        if (!this.config.meta || !this.config.meta.url) {
            return null;
        }
        return this.config.meta.url + 'api/';
    }

    public getCmdEndpoint(user: string, cmd: string, subCmd?: string): string {
        const url = this.apiEndpoint;
        if (!url) {
            return null;
        }
        return url + cmd + (subCmd ? '/' + subCmd : '');
    }

    private async loadServers(): Promise<Server[]> {
        if (!this.isAuthorized) {
            return null;
        }

        const url = this.getCmdEndpoint(this.id, 'wireguard', 'list');
        if (!url) {
            return null;
        }

        return HttpHelper.load(this.http, url, {
            timestamp: (+(new Date())) + ''
        }).pipe(
            catchError((error: HttpErrorResponse) => { return this.handleError(error, 'could not download wireguard servers'); }),
            map((resp: any) => {
                if (resp.status !== 'success') {
                    this.alert.error('could not get servers');
                    return null;
                }

                const res: Server[] = [];

                for (const dn in resp.servers) {
                    if (resp.servers.hasOwnProperty(dn)) {
                        const s = resp.servers[dn];

                        res.push({
                            id: dn,
                            cn: s.cn as string,
                            location: s.l as string
                        })
                    }
                }

                res.sort((a: Server, b: Server) => {
                    return a.location.localeCompare(b.location, this.locale, { sensitivity: 'base' });
                })

                this._servers = res;

                return res
            })).toPromise();
    }

    @Output()
    get servers(): Server[] {
        if (this._servers) {
            return this._servers;
        }
        this._servers = [];
        this.loadServers();
        return this._servers;
    }

    public async loadConfig(server: Server, allTraffic: boolean): Promise<string> {
        if (!this.isAuthorized) {
            return null;
        }

        const url = this.getCmdEndpoint(this.id, 'wireguard', 'config/' + server.cn + '/' + (allTraffic ? 'allTraffic' : 'default'));
        if (!url) {
            return null;
        }

        return HttpHelper.load(this.http, url, {
            timestamp: (+(new Date())) + ''
        }).pipe(
            catchError((error: HttpErrorResponse) => { return this.handleError(error, 'could not download wireguard config'); }),
            map((resp: any) => {
                if (resp.status !== 'success') {
                    this.alert.error('could not get config');
                    return null;
                }

                return resp.config as string;
            })).toPromise();
    }

    public async downloadConfig(server: Server, allTraffic: boolean) {
        this.loadConfig(server, allTraffic).then((config: string) => {
            if (!config) {
                return;
            }

            const bytes = config;

            var byteNumbers = new Array(bytes.length);
            for (var i = 0; i < bytes.length; i++) {
                byteNumbers[i] = bytes.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);

            var blob = new Blob([byteArray], { type: "text/plain;" });// charset=utf-8" });
            saveAs(blob, this.id + '-' + server.cn + '-' + (allTraffic ? 'all' : '') + '-wireguard.conf');//, { autoBom: true });
        })
    }

    public showQrCode(server: Server, allTraffic: boolean) {
        this.loadConfig(server, allTraffic).then((config: string) => {
            if (!config) {
                return;
            }

            //console.log(config)


            const dialogRef = this.confirm.open(QrCodeDialog, {
                width: '550px',
                data: {
                    title: 'Wireguard Config for ' + this.id + ' - ' + server.location + ' (' + server.cn + (allTraffic ? ', All Traffic' : '') + ')',
                    data: config
                }
            });

            dialogRef.afterClosed().subscribe(result => {

            });
        })
    }

    public handleError(error: HttpErrorResponse, msg: string): Observable<HttpEvent<any>> {
        this.alert.error(msg, true);
        //this.isLoading = false;

        return throwError(msg);
    }
}
