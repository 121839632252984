import { Component, Output, OnInit, ViewChild, AfterViewInit, Inject, LOCALE_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'common/lib/auth/authentication.service';
import { Validators, UntypedFormGroup, UntypedFormBuilder, AbstractControl, ValidatorFn, ValidationErrors, UntypedFormArray, NgForm } from '@angular/forms';
import { AlertService } from 'common/lib/auth/alert.service';
import { MustMatchValidator } from 'common/lib/auth/validator.must.match';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { HttpHelper } from 'common/lib/utils/http.helper';
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from 'common/lib/auth/config.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfirmationDialog } from '../widgets/confirm/confirm.options.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PasswordGenerator } from 'common/lib/utils/generate.password';
import { FileUploadValidators } from '@iplab/ngx-file-upload';


interface GroupData {
    cn: string;
    dn: string;
    description: string;
    members: string[];
}

interface UserData {
    id: string;
    sn: string;
    gn: string;
    dn: string;
    mail: string;
    desc: string;
    isSimpleRole: boolean;
    deactivated: boolean;
    memberof: string[];
    groups: { [id: string]: GroupData };
    groupids: string[];
    mailAliases: string[];
    mailForwardings: string[];
}

interface UsersData {
    groups: { [name: string]: GroupData };
    users: { [name: string]: UserData };
    status?: string;
}


@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, AfterViewInit {
    private users: UsersData = null;
    private _currentUsers: UserData[];
    private _filterString: string;
    private _editId: string = null;

    private groupsByDn: { [dn: string]: GroupData } = {};

    usersColumns: string[] = ['deactivated', 'simpleRole', 'id', 'gn', 'sn', 'mail', 'desc', 'cmds'];
    allUsersColumns: string[] = this.usersColumns;

    @Output()
    registerForm: UntypedFormGroup;


    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @Output()
    isLoading = true;


    @ViewChild('formDirective') private formDirective: NgForm;


    public readonly toggleVisbility = {
        'type': 'password'
    }


    constructor(
        private http: HttpClient,
        private alert: AlertService,
        private route: ActivatedRoute,
        private router: Router,
        private config: ConfigService,
        private authenticationService: AuthenticationService,
        private fb: UntypedFormBuilder,
        @Inject(LOCALE_ID) public locale: string,
        private confirm: MatDialog
    ) {

        this.registerForm = this.fb.group({
            activated: ['', []],
            simpleRole: ['', []],
            username: ['', [Validators.required, Validators.minLength(5)]],
            password: ['', [this.requiredPasswordValidator(Validators.required), Validators.minLength(6)]],
            confirmPassword: ['', [this.requiredPasswordValidator(Validators.required), Validators.minLength(6)]],
            surname: ['', [this.requiredNameValidator(Validators.required)]],
            givenname: ['', [this.requiredNameValidator(Validators.required)]],
            email: ['', [this.requiredEmailValidator(Validators.required), Validators.email]],
            groups: ['', []],
            desc: ['', []],
            aliases: this.fb.array([]),
            forwardings: this.fb.array([]),
            reset_photo: ['', []],
            files: ['', [/*Validators.required, requiredFileType('png')*/FileUploadValidators.filesLimit(1)]]
        }, {
            validator: MustMatchValidator('password', 'confirmPassword')
        });

        this.checkLoginStatus();

        this.authenticationService.userChanged.subscribe(_ => {
            this.checkLoginStatus();
        });


        this.controls.groups.valueChanges.subscribe(_ => {
            if (this.controls.username.value && !this.emailIsRequired && !this.controls.email.value) {
                this.controls.email.setValue((this.controls.username.value as string) + '@' + this.mainDomain);
            }
        });

        this.controls.username.valueChanges.subscribe(_ => {
            if ((this.registerForm.value['username'] as string || '') + '@' + this.mainDomain === this.controls.email.value) {
                this.controls.email.setValue((this.controls.username.value as string) + '@' + this.mainDomain);
            }
        });
    }

    public getData(user: UserData, col: string): any {
        switch (col) {
            case 'deactivated':
                return user.deactivated ? 'true' : 'false';

            case 'simpleRole':
                return user.isSimpleRole ? 'true' : 'false';

            case 'id':
                return user.id;

            case 'gn':
                return user.gn;

            case 'sn':
                return user.sn;

            case 'mail':
                return user.mail;

            case 'desc':
                return user.desc;

            case 'cmds':
                return null;
        }

        return null;
    }

    @Output()
    get mainDomain(): string {
        return this.config.meta.mainDomain;
    }

    @Output()
    get aliases(): UntypedFormArray {
        return this.controls.aliases as UntypedFormArray;
    }

    @Output()
    get forwardings(): UntypedFormArray {
        return this.controls.forwardings as UntypedFormArray;
    }

    @Output()
    get emailIsRequired(): boolean {
        if (this.registerForm && this.registerForm.controls && (this.controls.groups.value as string[] || []).indexOf('cn=mailbox,ou=groups,dc=hs-grafik,dc=net') !== -1) {
            return false;
        }

        return true;
    }

    private requiredPasswordValidator(validator: ValidatorFn): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if (this.editId !== 'new') {
                return null;
            }
            return validator(control);
        }
    }

    private requiredEmailValidator(validator: ValidatorFn): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if (!this.emailIsRequired) {
                return null;
            }
            return validator(control);
        }
    }


    @Output()
    get nameIsRequired(): boolean {
        if (this.registerForm && this.registerForm.controls && (this.controls.simpleRole.value as boolean)) {
            return false;
        }

        return true;
    }

    private requiredNameValidator(validator: ValidatorFn): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if (!this.nameIsRequired) {
                return null;
            }
            return validator(control);
        }
    }


    private emailAliasValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {

            return Validators.pattern('[a-zA-Z0-9!#\\$%&\'\\*\\+\\-/=\\?\\^_~]+(\\.[a-zA-Z0-9!#\\$%&\'\\*\\+\\-/=\\?\\^_~]+)*' +
                '(@[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*(\\.[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*)*)?')(control);
        }
    }


    ngOnInit() {

        this.isLoading = true;
        this.users = null;
        this._currentUsers = null;

        this.config.onConfig(() => {
            this.updateUsers();
        });
    }

    ngAfterViewInit() {
        this.sort.sortChange.subscribe(_ => {
            this._currentUsers = null;
            this.updateUsers();
        });

        this.updateUsers();
    }


    @Output()
    get controls(): { [key: string]: AbstractControl } {
        return this.registerForm.controls;
    }


    private checkLoginStatus() {

        // redirect to login if already logged in
        if (!this.authenticationService.hasLoginGroup('useradmin')) {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['login'], { queryParams: { redirect_url: this.router.routerState.snapshot.url } });
        }
    }


    get usersEndpoint(): string {
        if (!this.config.meta || !this.config.meta.authEndpoint) {
            return null;
        }
        return this.config.meta.authEndpoint + 'users/';
    }

    public getUserCmdEndpoint(user: string, cmd: string): string {
        const url = this.usersEndpoint;
        if (!url) {
            return null;
        }
        return url + user + '/' + cmd;
    }

    @Output()
    public loadUsers(): Observable<UsersData> {
        if (this.users) {
            return of(this.users);
        }

        /*
                if (this.users === undefined) {
                    return from(new Promise<UsersData>(resolve => {
                        while (this.users === undefined) {
                        }
        
                        resolve(this.users);
                    }))
                }
        
                this.users = undefined;*/


        const url = this.usersEndpoint;
        if (!url) {
            return of(null);
        }

        this.isLoading = true;

        return HttpHelper.load(this.http, url + 'list').pipe(
            map((users: UsersData) => {
                this.isLoading = false;
                if (users.status !== 'success') {
                    this.alert.error('could not get users');
                    return null;
                }

                //console.log(users);

                this.users = users;
                this._currentUsers = null;
                this.updateUsers();
                return users;
            }),
            catchError(() => {
                this.users = null
                this.isLoading = false;
                this.alert.error('could not get users');
                return of(null);
            }));
    }

    public compareUser(u1: UserData, u2: UserData, attribute?: string): number {
        const n1: string = attribute ? u1[attribute] : u1.id;
        const n2: string = attribute ? u2[attribute] : u2.id;

        if (typeof n1 === 'string' && typeof n2 === 'string') {
            return n1.localeCompare(n2, this.locale, { sensitivity: 'base' });
        }

        if (n1 < n2) {
            return -1;
        }
        if (n1 > n2) {
            return 1;
        }
        return 0;
    }


    @Output()
    public getUsers(): Observable<UserData[]> {
        const sort = this.sort ? this.sort.active : null;
        const order = this.sort ? this.sort.direction : null;

        return this.loadUsers().pipe(
            map((users: UsersData) => {
                let groups = this.extractGroups(users);
                let res = this.extractUsers(users);

                const groupsByDn: { [dn: string]: GroupData } = {};

                for (const g of groups) {
                    groupsByDn[g.dn] = g;
                }

                this.groupsByDn = groupsByDn;

                if (this._filterString && this._filterString !== '') {
                    res = res.filter(u => {

                        for (const col of this.allUsersColumns) {
                            const v = this.getData(u, col);
                            if (v !== null) {
                                if (v === this._filterString) {
                                    return true;
                                }

                                if (typeof v === 'string' && v.toLocaleLowerCase(this.locale).indexOf(this._filterString) !== -1) {
                                    return true;
                                }

                                if (Array.isArray(v)) {
                                    for (const s of v) {
                                        if (s === this._filterString) {
                                            return true;
                                        }

                                        if (typeof s === 'string' && s.toLocaleLowerCase(this.locale).indexOf(this._filterString) !== -1) {
                                            return true;
                                        }
                                    }
                                }
                            }
                        }

                        return false;
                    });
                }


                res = res.map((u: UserData) => {
                    u.groups = {};
                    u.groupids = [];
                    for (const g of u.memberof) {
                        u.groups[groupsByDn[g].cn] = groupsByDn[g];
                        u.groupids.push(groupsByDn[g].cn);
                    }
                    return u;
                });


                if (order === 'asc') {
                    res.sort((u1, u2) => { return this.compareUser(u1, u2, sort); });
                } else if (order === 'desc') {
                    res.sort((u1, u2) => { return this.compareUser(u1, u2, sort) * -1; });
                }

                this._editId = null;
                this._currentUsers = res;

                return res;
            })
        );
    }

    public updateUsers() {
        this.getUsers().subscribe();
    }

    @Output()
    get editId(): string {
        return this._editId;
    }

    @Output()
    get editUsername(): string {
        if (!this.editId || !this.users) {
            return null;
        }

        const user = this.users.users[this.editId];
        return user.id;
    }

    @Output()
    get currentUsers(): UserData[] {
        if (!this._currentUsers) {
            return [];
        }
        return this._currentUsers;
    }

    applyFilter(event?: Event) {
        if (!event) {
            this._filterString = null;

        } else {
            const filterValue = (event.target as HTMLInputElement).value;
            this._filterString = filterValue.trim().toLocaleLowerCase(this.locale);
        }

        this.updateUsers();
    }

    @Output()
    get filteredUsers(): UserData[] {
        if (!this.paginator) {
            return this.currentUsers;
        }
        return this.currentUsers.slice(this.paginator.pageIndex * this.paginator.pageSize, (this.paginator.pageIndex + 1) * this.paginator.pageSize);
    }

    @Output()
    get numberOfUsers(): number {
        if (!this._currentUsers) {
            return 0;
        }
        return this._currentUsers.length;
    }

    private extractUsers(users: UsersData): UserData[] {
        const res: UserData[] = [];
        if (!users) {
            return res;
        }

        for (const n in users.users) {
            if (users.users.hasOwnProperty(n)) {
                res.push(users.users[n]);
            }
        }

        return res;
    }

    @Output()
    get allUsers(): Observable<UserData[]> {
        return this.loadUsers().pipe(map(this.extractUsers));
    }

    private extractGroups(users: UsersData): GroupData[] {
        const res: GroupData[] = [];
        if (!users) {
            return res;
        }

        for (const n in users.groups) {
            if (users.groups.hasOwnProperty(n)) {
                res.push(users.groups[n]);
            }
        }

        return res;
    }

    @Output()
    get allGroups(): Observable<GroupData[]> {
        return this.loadUsers().pipe(map(this.extractGroups));
    }

    public toggleActivation(id: string, event?: MatSlideToggleChange) {
        const url = this.usersEndpoint;
        if (!url || !this.users) {
            return;
        }

        const user = this.users.users[id];
        if (!user) {
            return;
        }

        const deactivated = event ? event.checked : user.deactivated;

        this.isLoading = true;

        HttpHelper.load(this.http, url + id + (deactivated ? '/activate' : '/deactivate')).pipe(
            map((users: UsersData) => {
                this.isLoading = false;

                this.users = null;
                this.updateUsers();

                return null;
            }),
            catchError(() => {
                this.isLoading = false;
                this.alert.error('could not (de)activate user');
                return null;
            })).subscribe();
    }

    public createUser() {
        this._editId = 'new';

        this.controls.activated.setValue(true);
        this.controls.simpleRole.setValue(false);
        this.controls.simpleRole.enable()
        this.controls.username.reset();
        this.controls.password.reset();
        this.controls.confirmPassword.reset();
        this.controls.givenname.reset();
        this.controls.surname.reset();
        this.controls.email.reset();
        this.controls.desc.reset();
        this.controls.groups.reset();
        this.controls.files.reset();
        this.controls.reset_photo.reset();

        this.aliases.clear();
        this.forwardings.clear();
    }

    public editUser(id: string) {
        if (!this.users) {
            return;
        }


        this._editId = id;


        const user = this.users.users[id];
        if (!user) {
            return;
        }

        this.controls.activated.setValue(!user.deactivated);
        this.controls.simpleRole.setValue(user.isSimpleRole);
        this.controls.simpleRole.disable();
        this.controls.username.setValue(user.id);
        this.controls.password.reset();
        this.controls.confirmPassword.reset();
        this.controls.givenname.setValue(user.gn);
        this.controls.surname.setValue(user.sn);
        this.controls.email.setValue(user.mail);
        this.controls.desc.setValue(user.desc);
        this.controls.groups.setValue(user.memberof);
        this.controls.files.reset();
        this.controls.reset_photo.reset();

        this.aliases.clear();
        this.forwardings.clear();

        for (const m of user.mailAliases) {
            const g = this.fb.group({
                email: ['', [Validators.required, this.emailAliasValidator()]]
            });

            g.get('email').setValue(m);

            this.aliases.push(g);
        }

        for (const m of user.mailForwardings) {
            const g = this.fb.group({
                email: ['', [Validators.required, this.emailAliasValidator()]]
            });

            g.get('email').setValue(m);

            this.forwardings.push(g);
        }
    }

    public addAlias() {
        this.aliases.push(this.fb.group({
            email: ['', [Validators.required, this.emailAliasValidator()]]
        }));
    }

    public removeAlias(index: number) {
        this.aliases.removeAt(index);
    }

    public addForwarding() {
        this.forwardings.push(this.fb.group({
            email: ['', [Validators.required, this.emailAliasValidator()]]
        }));
    }

    public removeForwarding(index: number) {
        this.forwardings.removeAt(index);
    }

    public resetEdit() {
        this._editId = null;
    }

    public deleteUser(id: string) {
        const url = this.getUserCmdEndpoint(id, 'delete');
        if (!url || !this.users) {
            return;
        }

        const user = this.users.users[id];
        if (!user) {
            return;
        }


        const dialogRef = this.confirm.open(ConfirmationDialog, {
            width: '250px',
            data: { title: 'Delete User', message: 'Do you really want to delete the user "' + user.id + '"?' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;

                HttpHelper.load(this.http, url).pipe(
                    map((users: UsersData) => {
                        this.isLoading = false;

                        this.users = null;
                        this.updateUsers();

                        return null;
                    }),
                    catchError(() => {
                        this.isLoading = false;
                        this.alert.error('could not delete user');
                        return null;
                    })).subscribe();
            }
        });
    }



    public update() {
        this.users = null;
        this.updateUsers();
    }

    onSubmit() {
        // reset alerts on submit
        this.alert.clear();

        if (this.registerForm.invalid) {
            return;
        }

        const isSimpleUser = this.controls.simpleRole.value as boolean


        const username = this.controls.username.value as string;
        const sn = (this.controls.surname.value || '') as string;
        const gn = (this.controls.givenname.value || '') as string;


        let mail = (this.controls.email.value || '') as string;
        if (!mail || mail.length < 1) {
            mail = username + '@' + this.mainDomain;
        }

        const groups: string[] = [];
        if (this.controls.groups.value) {
            for (const gdn of this.controls.groups.value as string[]) {
                const g = this.groupsByDn[gdn] || undefined;
                if (g) {
                    groups.push(g.cn);
                }
            }
        }


        const aliases: string[] = [];
        for (let i = 0; i < this.aliases.length; i++) {
            const a = (this.aliases.at(i) as UntypedFormGroup).controls.email.value as string;
            if (a) {
                aliases.push(a);
            }
        }

        const forwardings: string[] = [];
        for (let i = 0; i < this.forwardings.length; i++) {
            const a = (this.forwardings.at(i) as UntypedFormGroup).controls.email.value as string;
            if (a) {
                forwardings.push(a);
            }
        }


        const user = this.editId == 'new' ? username : this._editId;


        var data = new FormData();
        data.append('activated', this.controls.activated.value as boolean || false ? '1' : '0');
        if (((this.controls.password.value || '') as string) != '') {
            data.append('password', this.controls.password.value as string);
        }
        data.append('sn', sn);
        data.append('givenName', gn);
        data.append('cn', isSimpleUser ? username : gn + ' ' + sn);
        data.append('description', (this.controls.desc.value || '') as string);
        /*if (this._editId != 'new') {
            data.append('username', username);
        }*/
        data.append('mail', mail);
        for (const g of groups) {
            data.append('groups[]', g);
        }
        for (const a of aliases) {
            data.append('mailAlternateAddress[]', a);
        }
        if (aliases.length <= 0) {
            data.append('mailAlternateAddress[null]', '');
        }
        for (const f of forwardings) {
            data.append('mailForwardingAddress[]', f);
        }
        if (forwardings.length <= 0) {
            data.append('mailForwardingAddress[null]', '');
        }

        if (this.controls.reset_photo.value as boolean) {
            data.append('photo', '');

        } else {
            const files = this.controls.files.value as File[] || []
            if (files && files.length > 0) {
                data.append('photo', files[0], files[0].name);
            }
        }

        /*const data = {
            activated: this.controls.activated.value as boolean || false ? '1' : '0',
            password: (this.controls.password.value as string || '') != '' ? this.controls.password.value as string : undefined,
            sn: sn,
            givenName: gn,
            cn: gn + ' ' + sn,
            description: this.controls.desc.value as string,
            username: this._editId != 'new' ? username || undefined : undefined,
            mail: mail,
            'group[]': groups,
            'mailAlternateAddress[]': aliases,
            'mailForwardingAddress[]': forwardings
        };*/


        let cmd = this._editId == 'new' ? 'create' : 'change'
        if (isSimpleUser) {
            cmd = cmd + '_simple';
        }

        const url = this.getUserCmdEndpoint(user, cmd);

        //console.log(url, user, data);



        this.isLoading = true;
        HttpHelper.upload(this.http, url, data).pipe(
            catchError((error: HttpErrorResponse) => { return this.handleError(error, this._editId == 'new' ? 'could not create user' : 'could not change user'); })
        ).subscribe((resp: any) => {
            this.isLoading = false;

            if (resp.status !== 'success') {
                this.alert.error(this._editId == 'new' ? 'could not create user' : 'could not change user');
                return;
            }

            this.formDirective.resetForm();
            this.resetEdit();
            this.users = null;
            this.updateUsers();
        });
    }

    public handleError(error: HttpErrorResponse, msg: string): Observable<HttpEvent<any>> {
        this.alert.error(msg, true);
        this.isLoading = false;

        return throwError(msg);
    }

    public generatePassword() {
        const g = new PasswordGenerator();

        if (this.controls.simpleRole.value as boolean) {
            g.includeSymbols = true
            g.passwordLength = 12

        } else {
            // somebody has to remember it, make it easier
            g.includeSymbols = false
        }

        const pass = g.generate()

        this.controls.password.setValue(pass)
        this.controls.confirmPassword.setValue(pass)

        return pass
    }

    public generateUsername() {

        const g = new PasswordGenerator();
        g.includeSymbols = false
        g.includeNumbers = false
        g.passwordLength = 6

        const user = 'account-' + g.generate()

        this.controls.username.setValue(user)
    }

    public generateEmail(username) {
        const email = username + '@' + this.config.meta.mainDomain;

        this.controls.email.setValue(email)
    }

    public accountTypeChanged(passwordVisbilityToggle) {
        if (this.controls.simpleRole.value as boolean) {

            if (!this.controls.username.value) {
                this.generateUsername();
            }

            if (!this.controls.password.value) {
                this.generatePassword();
            }

            if (!this.controls.email.value) {
                this.generateEmail(this.controls.username.value);
            }

            if (passwordVisbilityToggle) {
                passwordVisbilityToggle.isVisible = true
            }
        }
    }
}