import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, DialogOptionsComponent } from './app.component';
import { MaterialIncludeModule } from 'common/lib/material-include/material-include.module';
import { Service } from './app.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from 'common/lib/auth/auth.module';
import { ProfileComponent } from './pages/profile.component';
import { QRCodeModule } from 'angularx-qrcode';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { UsersComponent } from './pages/users.component';
import { ConfirmationDialog } from './widgets/confirm/confirm.options.component';
import { ConfirmationWithPasswordDialog } from './widgets/password/confirm.with.password.options.component';
import { CertificateInfoComponent } from './widgets/certificate/certinfo.component';
import { VpnInfoComponent } from './widgets/vpn/vpninfo.component';
import { DevicesComponent } from './pages/devices.component';
import { QrCodeDialog } from './widgets/qrcode/qrcode.options.component';
import { ProfileConfigComponent } from './widgets/profile.config/profile.config.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';

registerLocaleData(localeDe);


@NgModule({
  declarations: [
    AppComponent,
    DialogOptionsComponent,
    ProfileComponent,
    UsersComponent,
    DevicesComponent,
    ConfirmationDialog,
    ConfirmationWithPasswordDialog,
    QrCodeDialog,
    CertificateInfoComponent,
    VpnInfoComponent,
    ProfileConfigComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialIncludeModule,
    AuthModule.forRoot({
      oauthClient: 'intranet'
    }),
    //MatPasswordStrengthModule.forRoot(),
    QRCodeModule,
    FileUploadModule
  ],
  providers: [Service],
  bootstrap: [AppComponent]
})
export class AppModule { }
