export class CertificateInfo {
    serial: string;
    from: number;
    to: number;
}


export class User {
    id: string;
    dn: string;
    isUser: boolean;
    deactivated: boolean;
    gn: string;
    sn: string;
    mail: string;
    mailAliases: string[];
    mailForwardings: string[];
    certificate?: CertificateInfo;
    groups: { [name: string]: string }
}
