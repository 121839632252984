<h1 mat-dialog-title>Options of "{{data.service.description}}"</h1>
<div mat-dialog-content>
  <!--<mat-form-field>
      <mat-select matInput required [placeholder]="'Time Zone'" [(ngModel)]="data.timezone">
        <mat-option *ngFor="let tz of data.service.timezones" [value]="tz">{{tz}}</mat-option>
      </mat-select>
  </mat-form-field>-->
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>
