import { Component, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';



export interface DialogData {
  title: string;
  message: string;
  password: string;
}


@Component({
  selector: 'confirm.with.password.options.component',
  templateUrl: 'confirm.with.password.options.component.html',
})
export class ConfirmationWithPasswordDialog {

  @Output()
  registerForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationWithPasswordDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder) {

    this.registerForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]]
    }, {
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  @Output()
  get controls(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  save() {
    // reset alerts on submit

    if (this.registerForm.invalid) {
      this.data.password = '';
      return;
    }


    if ((this.controls.password.value as string || '') != '') {
      this.data.password = this.controls.password.value as string;
    }

    this.dialogRef.close(this.data);
  }
}
