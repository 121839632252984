import { Component, Output, OnInit, ViewChild, AfterViewInit, Inject, LOCALE_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'common/lib/auth/authentication.service';
import { Validators, UntypedFormGroup, UntypedFormBuilder, AbstractControl, NgForm, UntypedFormArray } from '@angular/forms';
import { AlertService } from 'common/lib/auth/alert.service';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { HttpHelper } from 'common/lib/utils/http.helper';
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from 'common/lib/auth/config.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfirmationDialog } from '../widgets/confirm/confirm.options.component';
import { MatDialog } from '@angular/material/dialog';
import { IPv4, IPv6 } from "ip-num/IPNumber";
import { IPv6CidrRange, IPv4CidrRange } from "ip-num/IPRange";
import * as IPv6Utils from 'ip-num/IPv6Utils'

import { COMMA, ENTER, SPACE, SEMICOLON } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';


interface NetworkData {
    dn: string;
    cn: string;
    description: string;
    iphostnumber: string | string[];
    ipnetworknumber: number;
    l: string;
}

interface Link {
    url: string;
    title: string;
    type?: string;
}

interface Filter {
    key: string
    title: string
}

/*interface UserData {
    dn: string;
    uid: string;
    cn: string | string[];
}*/

interface DeviceData {
    id: string;
    cn: string | string[];
    description: string;
    //deviceOwners?: string | string[];
    iphostnumber: string | string[];
    macaddress?: string;
    owner: string | string[];
    o: string | string[]; // no-ipv4 / no-ipv6
    labeleduri: string | string[];


    networksByLocation?: Map<string, NetworkData[]>;

    networkIds(): string[];
    locations(): string[];
    networks(): string[];
    dns(): string[];
    rawIps(): string[];
    ips(): string[];
    links(): Link[];
    filters(): string[];
}

interface DevicesData {
    devices: { [id: string]: DeviceData };
    networks: { [id: string]: NetworkData };
    status?: string;
}

/*interface UsersData {
    users: { [id: string]: UserData };
    status?: string;
}*/


@Component({
    selector: 'app-devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit, AfterViewInit {
    private devices: DevicesData;
    private _currentDevices: DeviceData[];
    /*private users: UsersData;
    private _currentUsers: UserData[];*/
    private _filterString: string;
    private _editId: string = null;

    devsColumns: string[] = ['location', 'network', 'name', 'ip', 'mac', 'cmds'];
    allDevsColumns: string[] = this.devsColumns.concat(['desc']);

    @Output()
    registerForm: UntypedFormGroup;


    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @Output()
    isLoading = true;


    readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE, SEMICOLON];


    @ViewChild('formDirective') private formDirective: NgForm;


    static ipRx = '[0-9]+' +
        '|[0-9]+\\s*-\\s*[0-9]+' +
        '|((25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[0-9]{1,2})\.(25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[0-9]{1,2})\.(25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[0-9]{1,2})\.(25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[0-9]{1,2}))' +
        '|((([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])))';

    private _allFilters: { [key: string]: string } = {
        'no-ipv4': 'No-IPv4',
        'no-ipv6': 'No-IPv6',
    }

    public readonly allFilters: Filter[] = []

    constructor(
        private http: HttpClient,
        private alert: AlertService,
        private route: ActivatedRoute,
        private router: Router,
        private config: ConfigService,
        private authenticationService: AuthenticationService,
        private fb: UntypedFormBuilder,
        @Inject(LOCALE_ID) public locale: string,
        private confirm: MatDialog
    ) {

        this.allFilters = []
        for (const key in this._allFilters) {
            if (Object.prototype.hasOwnProperty.call(this._allFilters, key)) {
                this.allFilters.push({
                    key: key,
                    title: this._allFilters[key]
                })
            }
        }


        this.registerForm = this.fb.group({
            desc: ['', []],
            networks: ['', [Validators.required]],
            dns: ['', [Validators.required]],
            mac: ['', [Validators.pattern('^(|[a-fA-F0-9]{12}|([a-fA-F0-9]{2}:){5}[a-fA-F0-9]{2})$')]],
            ips: ['', [Validators.pattern(
                '^\\s*(' +
                DevicesComponent.ipRx +
                ')(\\s*(,|;)\\s*(' +
                DevicesComponent.ipRx +
                '))*\\s*$'
            )]],
            //deviceOwners: ['', []],
            links: this.fb.array([]),
            filters: ['', []]
        }, {
        });


        this.checkLoginStatus();

        this.authenticationService.userChanged.subscribe(_ => {
            this.checkLoginStatus();
        });


        this.controls.mac.valueChanges.subscribe((o: string) => {
            if (!o) {
                return;
            }

            let v = o.trim().toLowerCase();

            if (v === '') {
                this.controls.mac.reset();
                return;
            }

            const m = v.match(/^([a-f0-9]{2}):?([a-f0-9]{2}):?([a-f0-9]{2}):?([a-f0-9]{2}):?([a-f0-9]{2}):?([a-f0-9]{2})$/);
            if (m) {
                v = m[1] + ':' + m[2] + ':' + m[3] + ':' + m[4] + ':' + m[5] + ':' + m[6];
            }

            if (v !== o) {
                this.controls.mac.setValue(v);
            }
        })


        this.controls.ips.valueChanges.subscribe((o: string) => {
            const ips: string[] = this.parseIps(o);
            if (!ips || ips.length <= 0) {
                if (this.controls.ips.value) {
                    this.controls.ips.reset();
                }
                return;
            }

            const v2 = ips.join(', ');

            if (v2 !== o) {
                this.controls.ips.setValue(v2);
            }
        })
    }

    public parseIps(str: string): string[] {
        if (!str) {
            return [];
        }

        let v = str.trim().toLowerCase();

        if (v === '') {
            return [];
        }

        const ips: string[] = [];
        const rx = new RegExp(DevicesComponent.ipRx);

        let lastWasEmpty = false;
        let allValid = true;

        for (const p of v.split(/,|;/)) {
            let ps = p.trim().toLowerCase();

            if (ps && ps !== '') {
                lastWasEmpty = false;

                const m = ps.match(rx);
                if (!m) {
                    allValid = false;

                } else {
                    const vs: string[] = [];
                    for (const s of ps.split('-')) {
                        vs.push(s.trim());
                    }

                    ps = vs.join(' - ');
                }

                ips.push(ps);

            } else if (!lastWasEmpty) {
                ips.push('');
                lastWasEmpty = true;
                allValid = false;
            }
        }

        if (allValid) {
            ips.sort((a: string, b: string) => {
                const aIsIPv4 = a.indexOf('.') !== -1;
                const aIsIPv6 = a.indexOf(':') !== -1;

                const bIsIPv4 = b.indexOf('.') !== -1;
                const bIsIPv6 = b.indexOf(':') !== -1;

                if (aIsIPv6) {
                    if (bIsIPv6) {
                        return a.localeCompare(b, this.locale, { sensitivity: 'base' });
                    }

                    return 1;
                }
                if (bIsIPv6) {
                    return -1;
                }

                if (aIsIPv4) {
                    if (bIsIPv4) {
                        return a.localeCompare(b, this.locale, { sensitivity: 'base' });
                    }

                    return 1;
                }
                if (bIsIPv4) {
                    return -1;
                }

                a = a.split('.')[0].trim();
                b = b.split('.')[0].trim();

                if (+a == +b) return 0;
                return +a < +b ? -1 : 1;
            });
        }

        return ips;
    }

    public getData(dev: DeviceData, col: string): any {
        switch (col) {
            case 'location':
                return dev.locations();

            case 'network':
                return dev.networks();

            case 'name':
                return dev.dns();

            case 'ip':
                return dev.ips();

            case 'mac':
                return dev.macaddress;

            case 'desc':
                return dev.description;

            case 'cmds':
                return null;
        }

        return null;
    }


    ngOnInit() {

        this.isLoading = true;
        this.devices = null;
        this._currentDevices = null;
        //this.users = null;

        this.config.onConfig(() => {
            this.updateDevices();
            //this.updateUsers();
        });
    }

    ngAfterViewInit() {
        this.sort.sortChange.subscribe(_ => {
            this._currentDevices = null;
            this.updateDevices();
            //this.updateUsers();
        });

        this.updateDevices();
        //this.updateUsers();
    }


    @Output()
    get controls(): { [key: string]: AbstractControl } {
        return this.registerForm.controls;
    }

    @Output()
    get links(): UntypedFormArray {
        return this.controls.links as UntypedFormArray;
    }

    @Output()
    get filters(): UntypedFormArray {
        return this.controls.filters as UntypedFormArray;
    }


    private checkLoginStatus() {

        // redirect to login if already logged in
        if (!this.authenticationService.hasLoginGroup('devices')) {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['login'], { queryParams: { redirect_url: this.router.routerState.snapshot.url } });
        }
    }


    get devEndpoint(): string {
        if (!this.config.meta || !this.config.meta.authEndpoint) {
            return null;
        }
        return this.config.meta.url + 'api/devices/';
    }

    public getDevCmdEndpoint(dev: string, cmd: string): string {
        const url = this.devEndpoint;
        if (!url) {
            return null;
        }
        return url + (!dev ? '' : dev + '/') + cmd;
    }

    public isIPv6(ip: string) {
        return ip.indexOf(':') !== -1
    }


    public formatFilter(filter: string) {
        if (filter in this._allFilters) {
            return this._allFilters[filter]
        }

        return filter
    }

    /*@Output()
    public loadUsers(): Observable<UsersData> {
        if (this.users) {
            return of(this.users);
        }

        const url = this.devEndpoint;
        if (!url) {
            return of(null);
        }

        this.isLoading = true;

        return HttpHelper.load(this.http, url + 'possibleOwners').pipe(
            map((data: UsersData) => {
                this.isLoading = false;
                if (data.status !== 'success') {
                    this.alert.error('could not get users');
                    return of(null);
                }


                for (const id in data.users) {
                    if (data.users.hasOwnProperty(id)) {
                        const user = data.users[id];


                        / *const owner: string[] = !dev.owner ? [] : Array.isArray(dev.owner) ? dev.owner as string[] : [dev.owner as string];


                        dev.networkIds = (function (): string[] {
                            return owner;
                        })* /
                    }
                }

                // console.log(data);

                this.users = data;
                this._currentUsers = null;
                this.updateUsers();
                return data;
            }),
            catchError(() => {
                this.isLoading = false;
                this.alert.error('could not get users');
                return of(null);
            }));
    }

    public compareUser(u1: UserData, u2: UserData, attribute?: string): number {
        const n1: string = attribute ? u1[attribute] : u1.cn;
        const n2: string = attribute ? u2[attribute] : u2.cn;

        if (itypeof n1 === 'string' && typeof n2 === 'string') {
            return n1.localeCompare(n2, this.locale, { sensitivity: 'base' });
        }

        if (n1 < n2) {
            return -1;
        }
        if (n1 > n2) {
            return 1;
        }
        return 0;
    }


    private extractUsers(users: UsersData): UserData[] {
        const res: UserData[] = [];
        if (!users) {
            return res;
        }

        for (const n in users.users) {
            if (users.users.hasOwnProperty(n)) {
                res.push(users.users[n]);
            }
        }

        return res;
    }

    @Output()
    public getUsers(): Observable<UserData[]> {
        const order = 'asc';

        return this.loadUsers().pipe(
            map((users: UsersData) => {
                let res = this.extractUsers(users);

                if (order === 'asc') {
                    res.sort((u1, u2) => { return this.compareUser(u1, u2); });
                } else if (order === 'desc') {
                    res.sort((u1, u2) => { return this.compareUser(u1, u2) * -1; });
                }

                this._editId = null;
                this._currentUsers = res;

                return res;
            })
        );
    }

    public getOwners(dev: DeviceData): UserData[] {
        const res: UserData[] = [];

        if (!dev.deviceOwners || !this.users) {
            return res;
        }

        if (Array.isArray(dev.deviceOwners)) {
            for (const o of dev.deviceOwners) {
                if (this.users.users[o]) {
                    res.push(this.users.users[o]);
                }
            }

        } else {
            const o = dev.deviceOwners as string;

            if (this.users.users[o]) {
                res.push(this.users.users[o]);
            }
        }

        return res;
    }

    public updateUsers() {
        this.getUsers().subscribe();
    }

    @Output()
    get allUsers(): Observable<UserData[]> {
        return this.loadUsers().pipe(map(this.extractUsers));
    }*/

    @Output()
    public loadDevices(): Observable<DevicesData> {
        if (this.devices) {
            return of(this.devices);
        }

        const url = this.devEndpoint;
        if (!url) {
            return of(null);
        }

        this.isLoading = true;

        return HttpHelper.load(this.http, url + 'list').pipe(
            map((data: DevicesData) => {
                this.isLoading = false;
                if (data.status !== 'success') {
                    this.alert.error('could not get devices');
                    return null;
                }

                for (const id in data.devices) {
                    if (data.devices.hasOwnProperty(id)) {
                        const dev = data.devices[id];
                        dev.id = id;

                        const owner: string[] = !dev.owner ? [] : Array.isArray(dev.owner) ? dev.owner as string[] : [dev.owner as string];
                        dev.networkIds = (function (): string[] {
                            return owner;
                        })

                        const filters: string[] = !dev.o ? [] : Array.isArray(dev.o) ? dev.o as string[] : [dev.o as string];

                        let useIpv4 = true;
                        let useIpv6 = true;
                        for (const f of filters) {
                            if (f === 'no-ipv4') {
                                useIpv4 = false
                                continue
                            }
                            if (f === 'no-ipv6') {
                                useIpv6 = false
                                continue
                            }
                        }

                        //dev.deviceOwners = !dev.deviceOwners ? [] : Array.isArray(dev.deviceOwners) ? dev.deviceOwners as string[] : [dev.deviceOwners as string];




                        dev.networksByLocation = new Map<string, NetworkData[]>();
                        for (const o of owner) {
                            if (data.networks[o]) {
                                const n = data.networks[o];

                                if (!dev.networksByLocation.has(n.l)) {
                                    dev.networksByLocation.set(n.l, []);
                                }

                                dev.networksByLocation.get(n.l).push(n);
                            }
                        }

                        dev.locations = (function (): string[] {
                            const res: string[] = [];

                            this.networksByLocation.forEach((networks: NetworkData[], l: string) => {
                                res.push(l);
                            })

                            return res;
                        })

                        dev.networks = (function (): string[] {
                            const s = new Set<string>();

                            this.networksByLocation.forEach((networks: NetworkData[], l: string) => {

                                for (const n of networks) {
                                    s.add(n.cn);
                                }
                            })

                            return Array.from(s.values()).sort();
                        })

                        dev.dns = (function (): string[] {
                            return !this.cn ? [] : (Array.isArray(this.cn) ? (this.cn as string[]) : [this.cn as string]);
                        })


                        dev.filters = (function (): string[] {
                            return filters;
                        })


                        dev.links = (function (): Link[] {
                            const res: Link[] = [];

                            const rx = new RegExp('/^([^\\s]+)(\\s+(.+))?(\\[([^\\[\\]]+)\\])?$/');

                            if (this.labeleduri) {
                                for (const r of Array.isArray(this.labeleduri) ? this.labeleduri as string[] : [this.labeleduri as string]) {

                                    const m = r.match(/^([^\s]+)(\s+(.*?[^\]])?\s*(\[\s*([^\[\]]+)\s*\])?)?$/);
                                    if (m) {

                                        res.push({
                                            url: m[1],
                                            title: m[3] ? m[3] : m[1],
                                            type: m[5] ? m[5] : null
                                        });

                                    } else {
                                        res.push({
                                            url: r,
                                            title: r
                                        });
                                    }

                                }
                            }


                            return res;
                        })




                        dev.rawIps = (function (): string[] {
                            const res: string[] = [];

                            for (const ip of Array.isArray(dev.iphostnumber) ? dev.iphostnumber as string[] : [dev.iphostnumber as string]) {

                                if (ip.indexOf('.') !== -1 || ip.indexOf(':') !== -1) {
                                    // its a IP
                                    res.push(ip);

                                } else {
                                    // it's just a number

                                    const vs = ip.split('-');

                                    const rs: string[] = [];

                                    for (const v of vs) {
                                        rs.push((+v.trim()) + '');
                                    }

                                    res.push(rs.join(' - '));
                                }
                            }

                            return res;
                        })



                        dev.ips = (function (): string[] {

                            const ranges: (IPv4CidrRange | IPv6CidrRange)[] = [];

                            this.networksByLocation.forEach((networks: NetworkData[], l: string) => {
                                for (const n of networks) {
                                    for (const r of Array.isArray(n.iphostnumber) ? n.iphostnumber as string[] : [n.iphostnumber as string]) {

                                        if (r.indexOf(':') !== -1) {
                                            ranges.push(IPv6CidrRange.fromCidr(r));
                                        } else {
                                            ranges.push(IPv4CidrRange.fromCidr(r));
                                        }

                                    }
                                }
                            });



                            const res: string[] = [];

                            for (const ip of Array.isArray(dev.iphostnumber) ? dev.iphostnumber as string[] : [dev.iphostnumber as string]) {

                                if (ip.indexOf('.') !== -1) {
                                    if (!useIpv4) {
                                        continue
                                    }

                                    // its a IP
                                    res.push(ip);

                                } else if (ip.indexOf(':') !== -1) {
                                    if (!useIpv6) {
                                        continue
                                    }

                                    // its a IP
                                    res.push(ip);

                                } else {
                                    // it's just a number

                                    const vs = ip.split('-');

                                    for (const r of ranges) {
                                        if (r instanceof IPv4CidrRange) {
                                            if (!useIpv4) {
                                                continue
                                            }

                                        } else {
                                            if (!useIpv6) {
                                                continue
                                            }
                                        }

                                        const rs: string[] = [];

                                        for (const v of vs) {

                                            if (r instanceof IPv4CidrRange) {
                                                const f = r.getFirst();
                                                rs.push(new IPv4(f.value + BigInt(+v.trim())).toString());
                                            } else {
                                                const f = r.getFirst();
                                                rs.push(IPv6Utils.collapseIPv6Number(new IPv6(f.value + BigInt(+v.trim())).toString()));
                                            }
                                        }

                                        res.push(rs.join(' - '));
                                    }
                                }
                            }

                            return res;
                        })
                    }
                }

                //console.log(data);

                this.devices = data;
                this._currentDevices = null;
                this.updateDevices();
                return data;
            }),
            catchError(() => {
                this.isLoading = false;
                this.alert.error('could not get devices');
                return of(null);
            }));
    }

    private static getValue(d: DeviceData, attribute?: string): string {
        if (!attribute) {
            return d.iphostnumber as string;
        }

        if (attribute === 'network') {
            const vs = d.networks();
            if (vs.length > 0) {
                return vs[0]
            }
            return ''
        }


        if (attribute === 'location') {
            const vs = d.locations();
            if (vs.length > 0) {
                return vs[0]
            }
            return ''
        }

        if (attribute === 'name') {
            const vs = d.dns();
            if (vs.length > 0) {
                return vs[0]
            }
            return ''
        }

        if (attribute === 'ip') {
            const vs = d.ips();
            if (vs.length > 0) {
                return vs[0]
            }
            return ''
        }

        if (attribute === 'mac') {
            return d.macaddress || ''
        }

        return d[attribute] as string;
    }

    public compareDevice(u1: DeviceData, u2: DeviceData, attribute?: string): number {
        const n1: string = DevicesComponent.getValue(u1, attribute);
        const n2: string = DevicesComponent.getValue(u2, attribute);

        if (typeof n1 === 'string' && typeof n2 === 'string') {
            return n1.localeCompare(n2, this.locale, { sensitivity: 'base' });
        }

        if (n1 < n2) {
            return -1;
        }
        if (n1 > n2) {
            return 1;
        }
        return 0;
    }


    @Output()
    get allNetworks(): Observable<NetworkData[]> {
        return this.loadDevices().pipe(map(this.extractNetworks));
    }

    @Output()
    public getDevices(): Observable<DeviceData[]> {
        const sort = this.sort ? this.sort.active : null;
        const order = this.sort ? this.sort.direction : null;

        return this.loadDevices().pipe(
            map((devices: DevicesData) => {
                let res = this.extractDevices(devices);

                if (this._filterString && this._filterString !== '') {
                    res = res.filter(u => {
                        for (const col of this.allDevsColumns) {
                            const v = this.getData(u, col);
                            if (v !== null) {
                                if (v === this._filterString) {
                                    return true;
                                }

                                if (typeof v === 'string' && v.toLocaleLowerCase(this.locale).indexOf(this._filterString) !== -1) {
                                    return true;
                                }

                                if (Array.isArray(v)) {
                                    for (const s of v) {
                                        if (s === this._filterString) {
                                            return true;
                                        }

                                        if (typeof s === 'string' && s.toLocaleLowerCase(this.locale).indexOf(this._filterString) !== -1) {
                                            return true;
                                        }
                                    }
                                }
                            }
                        }

                        return false;
                    });
                }

                if (order === 'asc') {
                    res.sort((u1, u2) => { return this.compareDevice(u1, u2, sort); });
                } else if (order === 'desc') {
                    res.sort((u1, u2) => { return this.compareDevice(u1, u2, sort) * -1; });
                }

                this._editId = null;
                this._currentDevices = res;

                return res;
            })
        );
    }

    public updateDevices() {
        this.getDevices().subscribe();
    }

    @Output()
    get editId(): string {
        return this._editId;
    }

    @Output()
    get editName(): string {
        if (!this.editId || !this.devices) {
            return null;
        }

        const dev = this.devices.devices[this.editId];
        return dev.dns().join(', ');
    }

    @Output()
    get currentDevices(): DeviceData[] {
        if (!this._currentDevices) {
            return [];
        }
        return this._currentDevices;
    }

    applyFilter(event?: Event) {
        if (!event) {
            this._filterString = null;

        } else {
            const filterValue = (event.target as HTMLInputElement).value;
            this._filterString = filterValue.trim().toLocaleLowerCase(this.locale);
        }

        this.updateDevices();
    }

    @Output()
    get filteredDevices(): DeviceData[] {
        if (!this.paginator) {
            return this.currentDevices;
        }
        return this.currentDevices.slice(this.paginator.pageIndex * this.paginator.pageSize, (this.paginator.pageIndex + 1) * this.paginator.pageSize);
    }

    @Output()
    get numberOfDevices(): number {
        if (!this._currentDevices) {
            return 0;
        }
        return this._currentDevices.length;
    }

    private extractDevices(devices: DevicesData): DeviceData[] {
        const res: DeviceData[] = [];
        if (!devices) {
            return res;
        }

        for (const n in devices.devices) {
            if (devices.devices.hasOwnProperty(n)) {
                res.push(devices.devices[n]);
            }
        }

        return res;
    }

    private extractNetworks(devices: DevicesData): NetworkData[] {
        const res: NetworkData[] = [];
        if (!devices) {
            return res;
        }

        for (const n in devices.networks) {
            if (devices.networks.hasOwnProperty(n)) {
                res.push(devices.networks[n]);
            }
        }

        return res;
    }

    @Output()
    get allDevices(): Observable<DeviceData[]> {
        return this.loadDevices().pipe(map(this.extractDevices));
    }

    public createDevice() {
        this._editId = 'new';

        this.controls.networks.reset();
        this.controls.dns.reset();
        this.controls.mac.reset();
        this.controls.ips.reset();
        this.controls.desc.reset();
        this.controls.filters.reset();
        //this.controls.deviceOwners.reset();
        this.links.clear();
    }

    public editDevice(id: string) {
        if (!this.devices) {
            return;
        }


        this._editId = id;


        const dev = this.devices.devices[id];
        if (!dev) {
            return;
        }

        this.controls.networks.setValue(dev.networkIds());
        this.controls.dns.setValue(dev.dns());
        this.controls.mac.setValue(dev.macaddress || '');
        this.controls.ips.setValue((dev.rawIps() || []).join(', '));
        this.controls.desc.setValue(dev.description);
        this.controls.filters.setValue(dev.filters());
        //this.controls.deviceOwners.setValue(dev.deviceOwners);
        this.links.clear();


        for (const l of dev.links()) {
            const g = this.fb.group({
                url: ['', [Validators.required]],
                title: ['', [Validators.required]],
                type: ['', []]
            });

            g.get('url').setValue(l.url);
            g.get('title').setValue(l.title);
            g.get('type').setValue(l.type);

            this.links.push(g);
        }
    }

    public addLink() {
        this.links.push(this.fb.group({
            url: ['', [Validators.required]],
            title: ['', [Validators.required]],
            type: ['', []]
        }));
    }

    public removeLink(index: number) {
        this.links.removeAt(index);
    }

    get dnsNames(): string[] {
        if (!this.controls.dns.value) {
            return [];
        }

        if (Array.isArray(this.controls.dns.value)) {
            return this.controls.dns.value;
        }

        return [this.controls.dns.value];
    }

    addDns(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            this.controls.dns.setValue(this.dnsNames.concat([value.trim()]));
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    removeDns(name: string): void {
        const dns = this.dnsNames;
        const index = dns.indexOf(name);

        if (index >= 0) {
            dns.splice(index, 1);
            this.controls.dns.setValue(dns);
        }
    }


    public resetEdit() {
        this._editId = null;
    }

    public deleteDevice(id: string) {
        const url = this.getDevCmdEndpoint(id, 'delete');
        if (!url || !this.devices) {
            return;
        }

        const dev = this.devices.devices[id];
        if (!dev) {
            return;
        }


        const dialogRef = this.confirm.open(ConfirmationDialog, {
            width: '250px',
            data: { title: 'Delete Device', message: 'Do you really want to delete the dev "' + (dev.dns().join(', ')) + '"?' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;

                HttpHelper.load(this.http, url).pipe(
                    map((devs: DevicesData) => {
                        this.isLoading = false;

                        this.devices = null;
                        this.updateDevices();

                        return null;
                    }),
                    catchError(() => {
                        this.isLoading = false;
                        this.alert.error('could not delete device');
                        return null;
                    })).subscribe();
            }
        });
    }



    onSubmit() {
        // reset alerts on submit
        this.alert.clear();

        if (this.registerForm.invalid) {
            return;
        }

        const desc = this.controls.desc.value as string;
        const networks = (this.controls.networks.value || []) as string[];
        const dns = (this.controls.dns.value || []) as string[];
        const mac = (this.controls.mac.value || '') as string;
        const ips = this.parseIps((this.controls.ips.value || '') as string);
        //const deviceOwners = (this.controls.deviceOwners.value || []) as string[];


        const links: string[] = [];
        for (let i = 0; i < this.links.length; i++) {
            const c = (this.links.at(i) as UntypedFormGroup).controls;

            let u = (c.url.value || '') as string;

            if (u && u !== '') {
                u = u.trim();

                const t = (c.title.value || '') as string;
                const type = (c.type.value || '') as string;

                if (t && t !== '' && u !== t.trim()) {
                    u += ' ' + t.trim();
                }

                if (type && type !== '') {
                    u += ' [' + type.trim() + ']';
                }

                links.push(u);
            }
        }

        const filters: string[] = !this.controls.filters.value ? [] :
            Array.isArray(this.controls.filters.value) ?
                this.controls.filters.value as string[] :
                [this.controls.filters.value as string];



        var data = new FormData();
        if (this.editId !== 'new') {
            data.append('dn', this.editId);
        }
        data.append('description', desc);
        for (const n of networks) {
            data.append('owner[]', n);
        }
        for (const n of dns) {
            data.append('cn[]', n);
        }
        if (mac && mac !== '') {
            data.append('macaddress', mac);
        }
        for (const ip of ips) {
            data.append('iphostnumber[]', ip);
        }
        for (const f of filters) {
            data.append('o[]', f);
        }

        for (const l of links) {
            data.append('labeleduri[]', l);
        }

        /*for (const o of deviceOwners) {
            data.append('deviceowners[]', o);
        }*/

        const url = this.getDevCmdEndpoint(null, this._editId == 'new' ? 'create' : 'change');

        // console.log(url, data);



        this.isLoading = true;
        HttpHelper.upload(this.http, url, data).pipe(
            catchError((error: HttpErrorResponse) => { return this.handleError(error, this._editId == 'new' ? 'could not create device' : 'could not change device'); })
        ).subscribe((resp: any) => {
            this.isLoading = false;

            if (resp.status !== 'success') {
                this.alert.error(this._editId == 'new' ? 'could not create device' : 'could not change device');
                return;
            }

            this.formDirective.resetForm();
            this.resetEdit();
            this.devices = null;
            this.updateDevices();
        });
    }

    public handleError(error: HttpErrorResponse, msg: string): Observable<HttpEvent<any>> {
        this.alert.error(msg, true);
        this.isLoading = false;

        return throwError(msg);
    }
}