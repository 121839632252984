import { Component, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';



export interface DialogData {
  title: string;
  data: string;
}


@Component({
  selector: 'qrcode.options.component',
  templateUrl: 'qrcode.options.component.html',
})
export class QrCodeDialog {

  constructor(
    public dialogRef: MatDialogRef<QrCodeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
