import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { HttpHelper, ResponseType } from '../utils/http.helper';
import { Observable } from 'rxjs';

@Pipe({
    name: 'securedimage'
})
export class SecureImagePipe implements PipeTransform {

    constructor(private http: HttpClient) { }

    transform(url: string) {
        return HttpHelper.load(this.http, url, undefined, ResponseType.Blob)
            .toPromise().then(async (data: Blob) => {

                return await new Observable<string>(obs => {
                    const reader = new FileReader();

                    reader.onerror = err => obs.error(err);
                    reader.onabort = err => obs.error(err);
                    reader.onload = () => obs.next(reader.result.toString());
                    reader.onloadend = () => obs.complete();

                    return reader.readAsDataURL(data);
                }).toPromise();
            }).catch(() => {
                return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
            });
    }
}