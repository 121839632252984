<form [formGroup]="registerForm" (ngSubmit)="onSubmit()" #formDirective="ngForm" *ngIf="editId">
    <mat-card class="profile-card">
        <mat-card-header>
            <mat-card-title>{{ editId === 'new' ? 'Add Device' : 'Change Device "' + editName + '"' }}
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <mat-form-field appearance="outline">
                <mat-label i18n>Networks</mat-label>
                <mat-select formControlName="networks" multiple required>
                    <mat-option *ngFor="let n of allNetworks | async" [value]="n.dn">{{ n.cn }} ({{ n.l }})</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label i18n>Local DNS Name(s)</mat-label>
                <mat-chip-grid #dnsList formControlName="dns" required>
                    <mat-chip *ngFor="let n of dnsNames" [removable]="true"
                        (removed)="removeDns(n)">
                        {{n}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="New Local DNS Name..." [matChipInputFor]="dnsList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                        (matChipInputTokenEnd)="addDns($event)">
                </mat-chip-grid>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label i18n>MAC Address</mat-label>
                <input matInput formControlName="mac">
                <mat-error *ngIf="controls.mac.hasError('pattern')">
                    <strong>Invalid</strong> Mac Address
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label i18n>IP Address</mat-label>
                <input matInput formControlName="ips" required
                    placeholder="You can specify number (ip in range), ip ranges (in the form of 120-180) and external IPv4/IPv6 addresses">
                <mat-error *ngIf="controls.ips.hasError('pattern')">
                    <strong>Invalid</strong> IP Address(es)
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label i18n>Options</mat-label>
                <mat-select formControlName="filters" multiple>
                    <mat-option *ngFor="let g of allFilters" [value]="g.key">{{ g.title }}</mat-option>
                </mat-select>
            </mat-form-field>

            <!--<mat-form-field appearance="outline">
                <mat-label i18n>Owner(s) (relevant for VPN access)</mat-label>
                <mat-select formControlName="deviceOwners" multiple>
                    <mat-option *ngFor="let n of allUsers | async" [value]="n.dn">{{ n.cn }}</mat-option>
                </mat-select>
            </mat-form-field>-->

            <div formArrayName="links">
                <mat-card class="device-link" *ngFor="let p of this.links.controls; let i = index">
                    <mat-card-content [formGroupName]="i">
                        <mat-form-field appearance="outline">
                            <mat-label i18n>Links</mat-label>
                            <input matInput type="url" formControlName="url" required>
                            <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="removeLink(i)">
                                <mat-icon>close</mat-icon>
                            </button>
                            <mat-error *ngIf="$any(this.links.at(i)).controls.url.hasError('required')">
                                Forwarding is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label i18n>Title</mat-label>
                            <input matInput formControlName="title" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label i18n>Type</mat-label>
                            <input matInput formControlName="type">
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>

                <button mat-stroked-button color="primary" (click)="addLink()">
                    <mat-icon>add</mat-icon>
                    Add Link
                </button>
            </div>

            <mat-form-field appearance="outline">
                <mat-label i18n>Description</mat-label>
                <textarea matInput formControlName="desc" cdkTextareaAutosize></textarea>
            </mat-form-field>

        </mat-card-content>

        <mat-card-actions>
            <button mat-raised-button type="submit" value="Submit" color="primary"
                [disabled]="isLoading || registerForm.invalid">{{ editId === 'new' ? 'New' : 'Change' }}</button>

            <button mat-stroked-button value="Submit" color="primary" (click)="resetEdit()">Cancel</button>
        </mat-card-actions>
    </mat-card>
</form>

<form #formDirective="ngForm" *ngIf="!editId">
    <mat-card class="profile-card">
        <mat-card-header>
            <mat-card-title>Devices</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <mat-spinner *ngIf="isLoading"></mat-spinner>

            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput #filter (keyup)="applyFilter($event)" placeholder="e.g. DNS name">
                <button mat-button *ngIf="filter.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="filter.value = ''; applyFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>


            <table mat-table [dataSource]="filteredDevices" class="devices-table" matSort matSortActive="network"
                matSortDisableClear matSortDirection="asc" style="width: 100%;" multiTemplateDataRows>

                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Location</th>
                    <td mat-cell *matCellDef="let row">
                        <!--{{ row.locations().join(', ') }}-->
                        <mat-chip-set>
                            <mat-chip *ngFor="let i of row.locations()">{{ i }}</mat-chip>
                        </mat-chip-set>
                    </td>
                </ng-container>

                <ng-container matColumnDef="network">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Network</th>
                    <td mat-cell *matCellDef="let row">
                        <!--{{ row.networks().join(', ') }}-->
                        <mat-chip-set>
                            <mat-chip *ngFor="let i of row.networks()">{{ i }}</mat-chip>
                        </mat-chip-set>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Local DNS name(s)</th>
                    <td mat-cell *matCellDef="let row">
                        <!--{{ row.dns().join(', ') }}-->
                        <mat-chip-set>
                            <mat-chip *ngFor="let i of row.dns()" class="dns">{{ i }}</mat-chip>
                        </mat-chip-set>
                    </td>
                </ng-container>

                <ng-container matColumnDef="ip">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>IP(s)</th>
                    <td mat-cell *matCellDef="let row">
                        <!--{{ row.ips() }}-->
                        <mat-chip-set>
                            <mat-chip *ngFor="let i of row.ips()" class="ip" [class.ipv6]="isIPv6(i)">{{ i }}</mat-chip>

                            <mat-chip *ngFor="let f of row.filters()" class="ip_filter">{{ formatFilter(f) }}</mat-chip>
                        </mat-chip-set>
                    </td>
                </ng-container>

                <ng-container matColumnDef="mac">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>MAC Address</th>
                    <td mat-cell *matCellDef="let row">
                        {{ row.macaddress }}
                    </td>
                </ng-container>


                <ng-container matColumnDef="cmds">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Commands</th>
                    <ng-container *matCellDef="let row">
                        <td mat-cell *ngIf="editId === row.id; else cmdsLabel">
                            <button mat-icon-button color="primary" aria-label="Cancel Edit" (click)="resetEdit()">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </td>
                        <ng-template #cmdsLabel>
                            <td mat-cell>
                                <button *ngIf="!editId" mat-icon-button color="primary" aria-label="Edit Device"
                                    (click)="editDevice(row.id)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button *ngIf="!editId" mat-icon-button color="primary" aria-label="Delete Device"
                                    (click)="deleteDevice(row.id)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-template>
                    </ng-container>
                </ng-container>






                <ng-container matColumnDef="detail0">
                    <td mat-cell *matCellDef="let row" [attr.colspan]="6">
                        <!--<div *ngIf="row.deviceOwners && row.deviceOwners.length > 0" class="details mat-elevation-z3">
                            Owner(s) (relevant for VPN access):

                            <ul>
                                <li *ngFor="let o of getOwners(row)">
                                    {{o.cn}}
                                </li>
                            </ul>
                        </div>-->
                        <div *ngIf="row.description" class="details mat-elevation-z3">
                            {{ row.description }}

                            <ul *ngIf="row.links().length > 0">
                                <li *ngFor="let l of row.links()"><a target="_blank" [href]="l.url">{{ l.title }}</a>
                                    <span class="linktype" *ngIf="l.type">{{l.type}}</span></li>
                            </ul>
                        </div>
                    </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="devsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: devsColumns;"
                    [class]="['mainRow', row.deactivated || editId ? 'inactive' : 'active']">
                </tr>

                <tr mat-row *matRowDef="let row; columns: ['detail0']"
                    [class]="['detailRow', row.deactivated || editId ? 'inactive' : 'active']"></tr>
            </table>

            <mat-paginator [length]="numberOfDevices" pageSize="50" [pageSizeOptions]="[5, 10, 20, 50, 100]"
                showFirstLastButtons>
            </mat-paginator>
        </mat-card-content>

        <mat-card-actions>
            <button mat-stroked-button color="primary" (click)="createDevice()">
                <mat-icon>add_to_queue</mat-icon>
                Add Device
            </button>
        </mat-card-actions>
    </mat-card>
</form>