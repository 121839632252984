
export enum ProtocolType {
    Http,
    Https,
    WebSocket,
    SecureWebSocket
}

export class BaseUrlHelper {


    baseUrl = '';
    token = '';
    type = ProtocolType.Http;

    constructor(defaultBaseUrl: string = null, type?: ProtocolType) {
        this.init(defaultBaseUrl, type);
    }

    init(defaultBaseUrl: string = null, type?: ProtocolType) {
        //console.log(window.location);

        if (!type) {
            if (!defaultBaseUrl) {
                type = ProtocolType.Http;

            } else if (defaultBaseUrl.startsWith('http://')) {
                type = ProtocolType.Http;
            } else if (defaultBaseUrl.startsWith('https://')) {
                type = ProtocolType.Https;
            } else if (defaultBaseUrl.startsWith('ws://')) {
                type = ProtocolType.WebSocket;
            } else if (defaultBaseUrl.startsWith('ws://')) {
                type = ProtocolType.SecureWebSocket;
            } else {
                type = ProtocolType.Http;
            }
        }


        this.baseUrl = BaseUrlHelper.getProtocolString(type) + '://localhost:8080/api/';
        this.type = type;

        if (defaultBaseUrl) {
            this.baseUrl = defaultBaseUrl;
        }

        let port = 0;



        if (port !== 0 || window.location.port !== '4200') { // node.js -> development
            if (port === 0) {
                port = +window.location.port;
            }

            const protocol = window.location.protocol.endsWith(':') ?
                window.location.protocol.substr(0, window.location.protocol.length - 1) : window.location.protocol;

            const t = BaseUrlHelper.getProtocolType(protocol);
            this.adjustProtocolType(t);

            const path = '/'; // window.location.pathname as string;
            this.baseUrl = BaseUrlHelper.getProtocolString(this.type) + '://' + window.location.hostname + (port ? ':' + port : '') +
                path + (path.length > 0 && !path.endsWith('/') ? '/' : '');//+ 'api/';

        } else {
            const p = this.baseUrl.indexOf('://');
            if (p >= 0) {
                const t = BaseUrlHelper.getProtocolType(this.baseUrl.substr(0, p));
                this.adjustProtocolType(t);

                this.baseUrl = BaseUrlHelper.getProtocolString(this.type) + this.baseUrl.substr(p);
            }
        }

        // console.log(this.baseUrl);


        let queryString = window.location.search as string;
        const pos = queryString.indexOf('?');
        if (pos >= 0) {
            queryString = queryString.substr(pos + 1);

            const parts = queryString.split('&');
            parts.forEach(p => {
                const temp = p.split('=');
                if (temp.length === 2) {
                    if (temp[0] === 'token') {
                        this.token = temp[1];
                    }
                }
            });
        }
    }

    public static isDevelopment(): boolean {
        return window.location.port === '4200';
    }

    public static getProtocolType(protocol: string): ProtocolType {
        if (protocol.toLowerCase() === 'http') {
            return ProtocolType.Http;

        } else if (protocol.toLowerCase() === 'https') {
            return ProtocolType.Https;

        } else if (protocol.toLowerCase() === 'ws') {
            return ProtocolType.WebSocket;

        } else if (protocol.toLowerCase() === 'wss') {
            return ProtocolType.SecureWebSocket;

        }

        return ProtocolType.Http;
    }

    public static getProtocolString(type: ProtocolType): string {
        switch (type) {
            case ProtocolType.Http: return 'http';
            case ProtocolType.Https: return 'https';
            case ProtocolType.WebSocket: return 'ws';
            case ProtocolType.SecureWebSocket: return 'wss';
        }
    }

    private adjustProtocolType(type: ProtocolType) {
        if (type === ProtocolType.Https || type === ProtocolType.SecureWebSocket) {
            this.type = (this.type === ProtocolType.WebSocket || this.type === ProtocolType.SecureWebSocket) ?
                ProtocolType.SecureWebSocket : ProtocolType.Https;

        } else {
            this.type = (this.type === ProtocolType.WebSocket || this.type === ProtocolType.SecureWebSocket) ?
                ProtocolType.WebSocket : ProtocolType.Http;
        }
    }
}