<form [formGroup]="registerForm" #formDirective="ngForm">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <div mat-dialog-content>
    <p>{{ data.message }}</p>

    <mat-form-field appearance="outline">
      <mat-label i18n>User Password</mat-label>
      <input formControlName="password" matInput type="password" required minlength="6">
      <mat-error *ngIf="controls.password.hasError('minlength')">
        Password is <strong>too short</strong>
      </mat-error>
      <mat-error *ngIf="controls.password.hasError('required')">
        Password is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" cdkFocusInitial>Cancel</button>
    <button mat-button (click)="save()" [disabled]="registerForm.invalid">Ok</button>
  </div>
</form>