import { Component, Output } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { User } from './user';

@Component({
    selector: 'lib-userinfo',
    templateUrl: './userinfo.component.html',
    styleUrls: ['./userinfo.component.css']
})
export class UserInfoComponent {
    _user: User;

    constructor(private authService: AuthenticationService) {
        authService.userChanged.subscribe((user: User) => {
            this._user = user;
        });

        this._user = this.authService.currentUserDirect;
    }

    @Output()
    get user(): User {
        return this._user;
    }

    @Output()
    get username(): string {
        const user = this.user;
        if (!user) {
            return null;
        }
        return user.id;
    }

    public logout() {
        this.authService.logout();
    }

    @Output()
    public get isUserAdmin(): boolean {
        const user = this.user;
        if (!user) {
            return false;
        }

        return !!user.groups['useradmin'];
    }

    @Output()
    public get isAdministrativeUser(): boolean {
        const user = this.user;
        if (!user) {
            return false;
        }

        return !user.isUser;
    }

    @Output()
    public get isDevicesAdmin(): boolean {
        const user = this.user;
        if (!user) {
            return false;
        }

        return !!user.groups['devices'];
    }
}
