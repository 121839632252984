<div> {{ formateDate(certificate.from*1000) }} -
    {{ formateDate(certificate.to*1000) }}

    <button mat-icon-button color="primary" (click)="recreateCert()" matTooltip="Renew Certificate">
        <mat-icon>autorenew</mat-icon>
    </button>

    <button mat-icon-button color="primary" (click)="downloadCert()" matTooltip="Download Certificate">
        <mat-icon>cloud_download</mat-icon>
    </button>

    <mat-progress-bar mode="determinate" color="warn"
        [value]="(currentTime/1000 - certificate.from)*100 / (certificate.to - certificate.from)">
    </mat-progress-bar>
</div>