<mat-toolbar color="primary">
  <button mat-flat-button [matMenuTriggerFor]="menu" aria-label="Main menu" *ngIf="this.userinfo.user">
    <mat-icon>menu</mat-icon>
    Menu
  </button>

  <mat-menu #menu="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="matAdminMenu">
      <mat-icon>settings</mat-icon>
      <span>Settings</span>
    </button>
    

    <mat-divider></mat-divider>
    <button mat-menu-item (click)="this.userinfo.logout()">
      <mat-icon>power_settings_new</mat-icon>
      <span>Logout {{ this.userinfo.username }}</span>
    </button>
  </mat-menu>

  <mat-menu #matAdminMenu="matMenu">
    <button mat-menu-item routerLink="intranet/profile">
      <mat-icon>person</mat-icon>
      <span>User Profile</span>
    </button>
    

    <button mat-menu-item routerLink="intranet/users" *ngIf="this.userinfo.isUserAdmin">
      <mat-icon>people</mat-icon>
      <span>Users</span>
    </button>
    

    <button mat-menu-item routerLink="intranet/devices" *ngIf="this.userinfo.isDevicesAdmin">
      <mat-icon>devices</mat-icon>
      <span>Devices</span>
    </button>
  </mat-menu>

  <span class="userinfo">
    <lib-userinfo></lib-userinfo>
  </span>

  <!--<span class="menu-spacer">
    <button mat-icon-button (click)="this.openOptions()" class="settings" matTooltip="Settings">
      <mat-icon>settings</mat-icon>
    </button>
  </span>-->
</mat-toolbar>
<lib-alert></lib-alert>
<router-outlet></router-outlet>
<footer>
  <p>{{this.service.description}} (on {{this.service.host}})</p>
</footer>