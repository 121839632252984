

export class PasswordGenerator {
    public includeLetters = true
    public includeNumbers = true
    public includeSymbols = true
    public passwordLength = 8

    

    public generate(): string {

        const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz'
        const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        const numbers = '0123456789'
        const symbols = '!@#$%^&*()_-,.'

        let availableCharacters = ''

        if (this.includeLetters) {
            availableCharacters += lowerCaseLetters
            availableCharacters += upperCaseLetters
        }

        if (this.includeNumbers) {
            availableCharacters += numbers
        }

        if (this.includeSymbols) {
            availableCharacters += symbols
        }

        availableCharacters.split('')
        const generatedPassword = []

        for (let i = 0; i < this.passwordLength; i += 1) {
            const max = availableCharacters.length
            const ran = Math.random()
            const idx = Math.floor(ran * (max + 1))

            generatedPassword.push(availableCharacters[idx])

        }

        return generatedPassword.join('')
    }
}