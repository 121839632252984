<form (ngSubmit)="onSubmit()" [formGroup]="registerForm" #formDirective="ngForm" *ngIf="isAdministrativeUser">
    <mat-card class="profile-card">
        <mat-card-header>
            <mat-card-title>User "{{ this.user.id }}"</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            This user can not be changed!
        </mat-card-content>
    </mat-card>
</form>

<form (ngSubmit)="onSubmit()" [formGroup]="registerForm" #formDirective="ngForm" *ngIf="!isAdministrativeUser">
    <mat-card class="profile-card">
        <mat-card-header>
            <mat-card-title>User "{{ this.user.id }}"</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field appearance="outline">
                <mat-label i18n>Surname</mat-label>
                <input matInput formControlName="surname" required>
                <mat-error *ngIf="controls.surname.hasError('required')">
                    Surname is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label i18n>Given Name</mat-label>
                <input matInput formControlName="givenname" required>
                <mat-error *ngIf="controls.givenname.hasError('required')">
                    Given Name is <strong>required</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label i18n>E-Mail</mat-label>
                <input matInput type="email" formControlName="email" required>
                <mat-error *ngIf="controls.email.hasError('required')">
                    E-Mail is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label i18n>New Password</mat-label>
                <input matInput type="password" formControlName="password" _required minlength="6">
                <mat-error *ngIf="controls.password.hasError('minlength')">
                    Password is <strong>too short</strong>
                </mat-error>
                <!--<mat-error *ngIf="controls.pass.hasError('required')">
                    Password is <strong>required</strong>
                </mat-error>-->
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label i18n>Confirm Password</mat-label>
                <input matInput type="password" formControlName="confirmPassword" _required minlength="6">
                <mat-error *ngIf="controls.confirmPassword.hasError('minlength')">
                    Password is <strong>too short</strong>
                </mat-error>
                <mat-error *ngIf="controls.confirmPassword.hasError('mustmatch')">
                    Passwords must <strong>match</strong>
                </mat-error>
                <!--<mat-error *ngIf="controls.confirmPassword.hasError('required')">
                    Password is <strong>required</strong>
                </mat-error>-->
            </mat-form-field>

            <table mat-table [dataSource]="groups" style="width: 100%;" *ngIf="groups.length &gt; 0">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef i18n>Group</th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef i18n>Description</th>
                    <td mat-cell *matCellDef="let element"> {{element.description}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="groupColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: groupColumns;"></tr>
            </table>

            <div class="profile_img">
                <h4>Profile Picture</h4>
                <mat-slide-toggle formControlName="reset_photo" #reset_photo>Remove Image</mat-slide-toggle>
                <file-upload formControlName="files" *ngIf="!reset_photo.checked" fileslimit="1" [multiple]="false" minsize="0 MB" maxsize="2 MB"
                    [accept]="'image/*'"></file-upload>
                <img
                    [src]="(user &amp;&amp; user.id ? usersEndpoint + user.id + '/photo' : '') | securedimage | async" />
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button type="submit" value="Submit" color="primary"
                [disabled]="isLoading || registerForm.invalid" i18n>Change</button>
        </mat-card-actions>
    </mat-card>
</form>

<form #formDirective="ngForm">
    <mat-card class="certificate-card" *ngIf="this.user.certificate">
        <mat-card-header>
            <mat-card-title>Certificate</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <app-certinfo [user]="this.user" (change)="update()"></app-certinfo>
        </mat-card-content>
    </mat-card>
</form>

<form #formDirective="ngForm">
    <mat-card class="certificate-card">
        <mat-card-header>
            <mat-card-title>Profile Config</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <app-profile-config [user]="this.user"></app-profile-config>
        </mat-card-content>
    </mat-card>
</form>

<form #formDirective="ngForm">
    <mat-card class="certificate-card" *ngIf="this.isInGroup('vpn')">
        <mat-card-header>
            <mat-card-title>VPN - Wireguard Profile</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <app-vpninfo [user]="this.user"></app-vpninfo>
        </mat-card-content>
    </mat-card>
</form>