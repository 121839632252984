import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, merge, of, throwError, from } from 'rxjs';
import { BaseUrlHelper } from '../utils/baseurl.helper';
import { OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { filter, timeout, map, catchError, take, mergeMap } from 'rxjs/operators';
import { AlertService } from './alert.service';

@Injectable({
    providedIn: 'root'
})
export class AuthTokenInterceptor implements HttpInterceptor {

    public static baseUrl = '';
    public static authUrl = '';

    constructor(private oauthService: OAuthService, private alert: AlertService) { }


    public handleError(error: HttpErrorResponse, msg: string): Observable<HttpEvent<any>> {
        this.alert.error(msg, true);

        return throwError(msg);
    }

    get authTokenEndpoint(): string {
        if (!AuthTokenInterceptor.authUrl) {
            return null;
        }
        return AuthTokenInterceptor.authUrl + 'access_token';
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with auth token if available

        //console.log(request.url, AuthTokenInterceptor.baseUrl, AuthTokenInterceptor.authUrl);
        //console.log(this.oauthService.getAccessToken());
        //console.log(this.oauthService.hasValidAccessToken())
        //console.log(this.authTokenEndpoint)


        if (request.url && ((AuthTokenInterceptor.baseUrl != '' && request.url.startsWith(AuthTokenInterceptor.baseUrl)) ||
            (AuthTokenInterceptor.authUrl != '' && request.url.startsWith(AuthTokenInterceptor.authUrl)))) {


            if (BaseUrlHelper.isDevelopment()) {
                request = request.clone({
                    headers: request.headers,
                    setHeaders: {
                        //Authorization: `Bearer safsfsdf`,
                        'Support-Local-Debugging': '42'
                    }/*,
                    setParams: {
                        timestamp: (+(new Date)) + ''
                    }*/,
                    //withCredentials: true
                });
            }


            //console.log(this.oauthService.getAccessToken(), this.oauthService.hasValidAccessToken(), this.oauthService.getRefreshToken());
            //console.log(AuthTokenInterceptor.authUrl, AuthTokenInterceptor.authUrl != '' ? request.url.startsWith(AuthTokenInterceptor.authUrl) : false);

            if (this.authTokenEndpoint != request.url) {
                // do not interfer with auth requests

                return ((AuthTokenInterceptor.authUrl != '' && this.oauthService.getAccessToken() &&
                    !this.oauthService.hasValidAccessToken() && !request.url.startsWith(AuthTokenInterceptor.authUrl)) ?

                    from(this.oauthService.refreshToken().catch((error: HttpErrorResponse) => {
                        //console.log(error);
                        return this.handleError(error, 'Could not extend current login session');

                    })).pipe(
                        map(_ => {
                            if (this.oauthService.hasValidAccessToken()) {
                                return this.oauthService.getAccessToken();
                            }
                            return of(null);
                        })
                    )
                    :
                    merge(
                        of(this.oauthService.getAccessToken()).pipe(
                            filter(token => (token ? true : false))
                        ),
                        this.oauthService.events.pipe(
                            filter(e => e.type === 'token_received'),
                            timeout(this.oauthService.waitForTokenInMsec || 0),
                            catchError(_ => of(null)), // timeout is not an error
                            map(_ => this.oauthService.getAccessToken())
                        )
                    )).pipe(
                        take(1),
                        mergeMap(token => {
                            if (token /*&& request.url != this.oauthService.tokenEndpoint*/ && this.oauthService.hasValidAccessToken()) {
                                const header = 'Bearer ' + token;
                                const headers = request.headers.set('Authorization', header);
                                request = request.clone({ headers });
                            }

                            return next
                                .handle(request)
                                //.pipe(catchError(err => this.handleError(err, 'todo')))
                                ;
                        })
                    );
            }
        }

        return next.handle(request);
    }
}
